import React, { Component } from 'react';
import { ExameData } from './Exame';
import { PacienteData } from './Paciente';
import { ExamePadraoData } from './ExamePadrao';
import { log, idade, formatDate, getConfigValue } from './Util';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

// get our fontawesome imports
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../custom.css'
import { getUser } from '../services/auth';

//var pathfile;
export class VideosExame extends Component{
    constructor(props) {
        super(props);
        //atualiza o state do componente
        this.state = {
            title: "Mídias do Exame", loading: true,
            exameData: new ExameData(), pacienteData: new PacienteData(), epData: new ExamePadraoData(),
                        fileVideo: "", fileVideoDesac: "", fileVideSelec: ""
        };
        //id do exame
        var exameid = this.props.match.params["exameid"];
        //this.diag = "";
        var pathfile = "";
        // carrega o state com dados para a edição
        this.populateData(exameid);
        // este binding é necessário para fazer o 'this' funcionar no callback  
        this.handleEnviar = this.handleEnviar.bind(this);
        this.handleEnviarTodos = this.handleEnviarTodos.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChangeVideo = this.handleChangeVideo.bind(this);
        this.handleChangeVideoDesac = this.handleChangeVideoDesac.bind(this);
        this.handleChangeVideoSelec = this.handleChangeVideoSelec.bind(this);
        this.handleAcoplar = this.handleAcoplar.bind(this);
        this.handleDesacoplar = this.handleDesacoplar.bind(this);
        this.handleSelecionar = this.handleSelecionar.bind(this);
        this.handleSelecionarTodos = this.handleSelecionarTodos.bind(this);
        this.handleRetirar = this.handleRetirar.bind(this);
        this.handleLimpar = this.handleLimpar.bind(this);
        this.handleAcoplarTodos = this.handleAcoplarTodos.bind(this);
        this.handleDesacoplarTodos = this.handleDesacoplarTodos.bind(this);
    }

    render() {
        let conteudo = this.state.loading
            ? <p><em>Carregando...</em></p>
            : this.renderDataTable();
        return <div>
            <h2>{this.state.title}</h2>
            {conteudo}
        </div>;
    }

    // trata o evento do botão retirar
    handleRetirar(e) {
        let comboVideosSelec = document.getElementById("cboVideosSelec");
        comboVideosSelec.remove(comboVideosSelec.selectedIndex);
    }

    // trata o evento do botão limpar
    handleLimpar(e) {
        let comboVideosSelec = document.getElementById("cboVideosSelec");
        while (comboVideosSelec.length > 0) {
            comboVideosSelec.remove(0);
        }
    }

    // trata o evento do botão acoplar
    handleAcoplar(e) {
        let comboVideosDesac = document.getElementById("cboVideosDesac");        
        if (!window.confirm("Deseja acoplar a mídia " + comboVideosDesac.value + " a este exame ?"))
            return;
        let comboVideos = document.getElementById("cboVideos");
        this.putFileExam(this.state.exameData.id, comboVideosDesac.value, null);
        let opt = document.createElement("option");
        opt.value = comboVideosDesac.value;
        opt.text = comboVideosDesac.value;
        comboVideos.add(opt, comboVideos.options[comboVideos.length]); 
        comboVideosDesac.remove(comboVideosDesac.selectedIndex);
    }

    // trata o evento do botão acoplar todos
    handleAcoplarTodos(e) {
        if (!window.confirm("Deseja acoplar todas as mídias desacopladas a este exame ?"))
            return;
        let comboVideosDesac = document.getElementById("cboVideosDesac");
        let comboVideos = document.getElementById("cboVideos");
        let opt
        while (comboVideosDesac.length > 0) {
            this.putFileExam(this.state.exameData.id, comboVideosDesac.options[0].value, null);
            opt = document.createElement("option");
            opt.value = comboVideosDesac.options[0].value;
            opt.text = comboVideosDesac.options[0].value;
            comboVideos.add(opt, comboVideos.options[comboVideos.length]);
            comboVideosDesac.remove(0);
        }
    }

    // trata o evento do botão desacoplar
    handleDesacoplar(e) {        
        let comboVideosDesac = document.getElementById("cboVideosDesac");
        let comboVideos = document.getElementById("cboVideos");
        if (!window.confirm("Deseja desacoplar a mídia " + comboVideos.value + " deste exame ?"))
            return;
        let comboVideosSelec = document.getElementById("cboVideosSelec");
        this.putFileExam(0, comboVideos.value, null);
        let opt = document.createElement("option");
        opt.value = comboVideos.value;
        opt.text = comboVideos.value;
        comboVideosDesac.add(opt, comboVideosDesac.options[comboVideosDesac.length+1]);
        for (let i = 0; i < comboVideosSelec.length; i = i + 1) {
            if (comboVideosSelec.options[i].value == comboVideos.value) {
                comboVideosSelec.remove(i);
                break
            }
        }
        comboVideos.remove(comboVideos.selectedIndex);
    }

    // trata o evento do botão desacoplar todos
    handleDesacoplarTodos(e) {
        if (!window.confirm("Deseja desacoplar todas as mídias selecionadas ?"))
            return;
        let comboVideosDesac = document.getElementById("cboVideosDesac");
        let comboVideos = document.getElementById("cboVideos");
        let comboVideosSelec = document.getElementById("cboVideosSelec");
        let opt
        while (comboVideosSelec.length > 0) {
            this.putFileExam(0, comboVideosSelec.options[0].value, null);
            opt = document.createElement("option");
            opt.value = comboVideosSelec.options[0].value;
            opt.text = comboVideosSelec.options[0].value;
            comboVideosDesac.add(opt, comboVideosDesac.options[comboVideosDesac.length]);
            for (let j = 0; j < comboVideos.length; j = j + 1) {
                if (comboVideosSelec.options[0].value == comboVideos.options[j].value) {
                    comboVideos.remove(j);
                    break
                }
            }
            comboVideosSelec.remove(0);
        }
    }

    // trata o evento do botão selecionar
    handleSelecionar(e) {
        const comboVideos = document.getElementById("cboVideos");
        let comboVideosSelec = document.getElementById("cboVideosSelec");

        for (let i = 0; i < comboVideosSelec.length; i = i + 1) {
            if (comboVideosSelec.options[i].value == comboVideos.value)
                return
        }
        
        let opt = document.createElement("option");
        opt.value = comboVideos.value;
        opt.text = comboVideos.value;
        comboVideosSelec.add(opt, comboVideosSelec.options[comboVideosSelec.length]);
        comboVideosSelec.value = opt.value;
        const video = this.pathfile + opt.value;
        this.setState(() => ({
            fileVideoSelec: video
        }));
        
    }

    // trata o evento do botão selecionar todos
    handleSelecionarTodos(e) {
        const comboVideos = document.getElementById("cboVideos");
        let comboVideosSelec = document.getElementById("cboVideosSelec");

        while (comboVideosSelec.length > 0) {
            comboVideosSelec.remove(0);
        }
        let opt;
        for (let i = 0; i < comboVideos.length; i = i + 1) {
            opt = document.createElement("option");
            opt.value = comboVideos.options[i].value;
            opt.text = comboVideos.options[i].value;
            comboVideosSelec.add(opt, comboVideosSelec.options[i]);
        }
        comboVideosSelec.value = comboVideosSelec.options[0].value;
        const video = this.pathfile + comboVideosSelec.value;
        this.setState(() => ({
            fileVideoSelec: video
        }));
    }

    // trata o evento do botão cancela
    handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/exame/1");
    }

    // trata o evento alterar selecao de elemento da lista
    handleChangeVideo(e) {
        e.preventDefault();
        //const video = e.text;
        const video = this.pathfile + e.target.value;
        this.setState(() => ({
            fileVideo: video
        }));
        //this.confirm = true;
        //this.handleSave(e);
    }

    // trata o evento alterar selecao de elemento da lista
    handleChangeVideoDesac(e) {
        e.preventDefault();
        //const video = e.text;
        const video = this.pathfile + e.target.value;
        this.setState(() => ({
            fileVideoDesac: video
        }));
        //this.confirm = true;
        //this.handleSave(e);
    }

    // trata o evento alterar selecao de elemento da lista
    handleChangeVideoSelec(e) {
        e.preventDefault();
        //const video = e.text;
        const video = this.pathfile + e.target.value;
        this.setState(() => ({
            fileVideoSelec: video
        }));
        //this.confirm = true;
        //this.handleSave(e);
    }

    // trata o evento enviar registro
    handleEnviar(e) {
        e.preventDefault();
        this.enviarFileExam(false)
    }

    // trata o evento enviar selecionados
    handleEnviarTodos(e) {
        e.preventDefault();
        this.enviarFileExam(true)
    }

    async enviarFileExam(enviarSelecionados) {        
        const regExam = this.state.exameData;
        var json = JSON.stringify(regExam);
        // PUT solicitação para editar exame
        if (this.state.exameData.id) {
            json = json.replace("{", "{\"id\":" + this.state.exameData.id + ",");
            let envio = 3;
            json = json.replace("}", ",\"envio\":" + envio + "}");
            try {
                let options = {method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: json, };
                fetch('api/Exames/' + this.state.exameData.id, options,)
                    .then((response) => log("EDIT", "Alterou e enviou Video Exame: " + json))
                    .then((responseJson) => {
                        this.props.history.push("/exame/1");
                    })
            } catch (e) { console.log(e); }

            ///// file exame /////
            let comboVideos;
            if (enviarSelecionados == false) {
                comboVideos = document.getElementById("cboVideos");
                await this.putFileExam(regExam.id, comboVideos.value, envio)
            }
            else {
                comboVideos = document.getElementById("cboVideosSelec");
                for (let i = 0; i < comboVideos.length; i = i + 1) {
                    await this.putFileExam(regExam.id, comboVideos.options[i].value, envio)
                }
            }
            /*let regFileExam = new ExameFileData;
            regFileExam.id_exame = regExam.id;
            regFileExam.file = comboVideos.value;
            regFileExam.envio = envio;
            json = JSON.stringify(regFileExam);
            try {
                let options = { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: json, };
                fetch('api/ExameFiles/' + regFileExam.file, options,)
                    .then((response) => log("EDIT", "Alterou e enviou Video Exame: " + json))
                    .then((responseJson) => {
                        this.props.history.push("/exame/1");
                    })
            } catch (e) { console.log(e); }*/
        }
    }

    putFileExam(id, video, envio) {
        ///// file exame /////
        //const comboVideos = document.getElementById("cboVideos");
        let regFileExam = new ExameFileData;
        regFileExam.id_exame = id;
        regFileExam.file = video;
        regFileExam.envio = envio;
        let json = JSON.stringify(regFileExam);
        if (envio == null) {
            json = json.replace(",\"envio\":" + envio + "}", "}");
        }
        try {
            let options = { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: json, };
            fetch('api/ExameFiles/' + regFileExam.file, options,)
                .then((response) => log("EDIT", "Alterou e enviou Video Exame: " + json))
                .then((responseJson) => {
                    //this.props.history.push("/exame/1");
                })
        } catch (e) { console.log(e); }
    }

    // Carrega o combobox de vídeos deste exame
    async loadComboVideos() {
        /**
         * Carregando a combobox Vídeos
         */
        const data = await fetch('api/ExameFiles/'+this.state.exameData.id);
        const listVideos = await data.json();
        const comboVideos = document.getElementById("cboVideos");
        var opt;
        /*var opt = document.createElement("option");
        opt.value = "0";
        opt.text = "";
        comboVideos.add(opt, comboVideos.options[0]);*/
        for (var i = 0; i < listVideos.length; i++) {
            opt = document.createElement("option");
            opt.value = listVideos[i].file;
            opt.text = listVideos[i].file;
            comboVideos.add(opt, comboVideos.options[i]);
        };

        if (listVideos.length > 0) {
            comboVideos.value = opt.value;
            const video = this.pathfile + opt.value;
            this.setState(() => ({
                fileVideo: video
            }));
        }
    }

    // Carrega o combobox de vídeos sem exame
    async loadComboVideosDesacoplados() {
        /**
         * Carregando a combobox Vídeos
         */
        const data = await fetch('api/ExameFiles/0');
        const listVideos = await data.json();
        const comboVideos = document.getElementById("cboVideosDesac");
        var opt;
        /*var opt = document.createElement("option");
        opt.value = "0";
        opt.text = "";
        comboVideos.add(opt, comboVideos.options[0]);*/
        for (var i = 0; i < listVideos.length; i++) {
            opt = document.createElement("option");
            opt.value = listVideos[i].file;
            opt.text = listVideos[i].file;
            comboVideos.add(opt, comboVideos.options[i]);
        };

        if (listVideos.length > 0) {
            comboVideos.value = opt.value;
            const video = this.pathfile + opt.value;
            this.setState(() => ({
                fileVideoDesac: video
            }));
        }
    }

    renderDataTable() {
        const regExam = this.state.exameData;
        const regPac = this.state.pacienteData;
        const regEP = this.state.epData;
        return (
          <>
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Exame</th>
                        <th>Descrição</th>
                        <th>Data</th>
                        <th>Paciente</th>
                        <th>Idade</th>
                        <th>Sexo</th>
                        <th>Convênio</th>
                        <th>Solicitante</th>
                    </tr>
                </thead>
                <tbody>
                    {
                         <tr key={regExam.id}>
                            <td>{regExam.id}</td>
                            <td>{regEP.nome}</td>
                            <td>{regExam.descricao}</td>
                            <td>{formatDate(regExam.data)}</td>
                            <td>{regPac.nome}</td>
                            <td>{idade(regPac.datanascimento)}</td>
                            <td>{regPac.sexo}</td>
                            <td>{regExam.convenio}</td>
                            <td>{regExam.solicitante}</td>
                        </tr>
                    }
                </tbody>
            </table>
            <div>
                {this.renderCreatePlayer()}
            </div>
            {/* <div>{this.renderCreateForm()}</div> */}
          </>
        );
    }

    renderCreateForm() {
    return (
        <div className="form-group row" >
            <div className="col-md-6">
                <div className="form-group">
                    <button type="submit" className="btn btn-primary" onClick={this.handleEnviar}>Enviar</button>
                    <button className="btn btn-primary" onClick={this.handleCancel}>Voltar</button>
                </div >
            </div >
        </div>);
    }

    renderCreatePlayer() {
        //<video source src={this.state.fileVideo} width="640" height="360" controls>
        var isImg = this.state.fileVideo.indexOf(".png") != -1;
        return (
                <MDBContainer>
                    <MDBRow>
                    <MDBCol size="7">
                        <MDBRow>
                            <label className="control-label" htmlFor="br"></label>
                        </MDBRow>
                        <MDBRow>
                            <video style={{ display: isImg ? 'none' : 'inline' }} source src={this.state.fileVideo} width="640" height="360" controls>
                                Seu navegador não suporta o elemento <code>video</code>.
                            </video>
                            <img class="quadro scale-down" style={{ display: isImg ? 'inline' : 'none' }} src={this.state.fileVideo} alt="640 x 360"/>
                        </MDBRow>                        
                        <MDBRow>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={this.handleEnviar}>Enviar</button>
                                <button className="btn btn-primary" onClick={this.handleCancel}>Voltar</button>
                            </div >
                        </MDBRow>
                    </MDBCol>
                    <MDBCol size="5">
                            <MDBRow>
                                <label className="control-label" htmlFor="id_exame">Mídias do Exame</label>
                            </MDBRow>
                            <MDBRow>
                                    <select className="form-control" id="cboVideos" name="videos" onChange={this.handleChangeVideo}
                                        defaultValue={"ExameFile"} required>
                                    </select>
                            </MDBRow>
                            <MDBRow>

                                <p>
                                    <a href={this.state.fileVideo} target="_blank" className="btn btn-primary">
                                        Abrir <spin><FontAwesomeIcon icon={faPlay} /></spin></a>
                                    <button type="submit" className="btn btn-primary" onClick={this.handleDesacoplar}>Desacoplar</button>
                                <button type="submit" className="btn btn-primary" onClick={this.handleSelecionar}>Selecionar</button>
                                <button type="submit" className="btn btn-primary" onClick={this.handleSelecionarTodos}>Selecionar Todos</button>
                                </p>
                            </MDBRow>                        
                            <MDBRow>
                                <label className="control-label" htmlFor="id_exame">Mídias Desacopladas</label>
                            </MDBRow>
                            <MDBRow>
                                <select className="form-control" id="cboVideosDesac" name="videosdesac" onChange={this.handleChangeVideoDesac}
                                    defaultValue={"ExameFile"} required>
                                </select>
                            </MDBRow>
                            <MDBRow>
                                <p>
                                    <a href={this.state.fileVideoDesac} target="_blank" className="btn btn-primary">
                                    Abrir <spin><FontAwesomeIcon icon={faPlay} /></spin></a>
                                <button type="submit" className="btn btn-primary" onClick={this.handleAcoplar}>Acoplar</button>
                                <button type="submit" className="btn btn-primary" onClick={this.handleAcoplarTodos}>Acoplar Todos</button>                                    
                                </p>
                        </MDBRow>
                        <MDBRow>
                            <label className="control-label" htmlFor="id_exame">Mídias Selecionadas</label>
                        </MDBRow>
                        <MDBRow>
                            <select className="form-control" id="cboVideosSelec" name="videosselec" onChange={this.handleChangeVideoSelec}
                                defaultValue={"ExameFile"} required>
                            </select>
                        </MDBRow>
                        <MDBRow>
                            <p>
                                <a href={this.state.fileVideoSelec} target="_blank" className="btn btn-primary">
                                    Abrir <spin><FontAwesomeIcon icon={faPlay} /></spin></a>
                                <button type="submit" className="btn btn-primary" onClick={this.handleRetirar}>Retirar</button>
                                <button type="submit" className="btn btn-primary" onClick={this.handleLimpar}>Limpar</button>
                                <button type="submit" className="btn btn-primary" onClick={this.handleDesacoplarTodos}>Desac. Todos</button>
                                <button type="submit" className="btn btn-primary" onClick={this.handleEnviarTodos}>Enviar Todos</button>
                            </p>
                        </MDBRow>
                    </MDBCol>
                    
                    </MDBRow>
                </MDBContainer>
            );
    }

    async populateData(exid) {
        let response = await fetch('api/Exames/' + exid);
        const dataExam = await response.json();
        response = await fetch('api/Pacientes/' + dataExam.id_paciente.toString());
        const dataPac = await response.json();
        response = await fetch('api/ExamePadrao/' + dataExam.id_exame_padrao.toString());
        const dataEP = await response.json();
        this.setState(() => ({
            title: "Mídias do Exame", loading: false,
            exameData: dataExam, pacienteData: dataPac, epData: dataEP
        }));
        this.pathfile = await getConfigValue('path_file');
        await this.loadComboVideos();
        await this.loadComboVideosDesacoplados();
    }
}  

export class ExameFileData {
    id_exame;
    file;
    envio;
}