import React, { Component } from 'react';

// get our fontawesome imports
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUser } from '../services/auth';
import '../custom.css'

export class Usuario extends Component {
    static displayName = Usuario.name;

  constructor(props) {
    super(props);
      this.state = { listaUsuario: [], strFilter: '', loading: true };
      // este binding é necessário para que o 'this' funcione no callback  
      this.handleDelete = this.handleDelete.bind(this);
      this.handleEdit = this.handleEdit.bind(this);
      this.handleFilter = this.handleFilter.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

    renderDataTable(listaUsuario) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th>Nível</th>
            <th>Login</th>
            <th>Comandos</th>
          </tr>
        </thead>
        <tbody>
          {listaUsuario.map(user =>
            <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.nome}</td>
                <td>{user.nivel}</td>
                <td>{user.login}</td>
                <td>
                        <button className="btn btn-edit btn-edit-l" onClick={() => this.handleEdit(user.id)}>Editar</button>  
                        <button className="btn btn-del btn-del-l" onClick={() => this.handleDelete(user.id, user.nome)}>Deletar</button>
                </td>
            </tr>
          )}
        </tbody>
      </table>
    );
}

render() {
        let contents;
        if (this.state.loading) {
            contents = <p><em>Carregando...</em></p>
        } else {
            let filtered = this.state.listaUsuario.filter((rec) => {
                return (rec.id.toString().indexOf(this.state.strFilter) != -1 ||
                    rec.nome.indexOf(this.state.strFilter) != -1 ||
                    rec.nivel.indexOf(this.state.strFilter) != -1 ||
                    rec.login.indexOf(this.state.strFilter) != -1
                );
            });
            contents = this.renderDataTable(filtered);
        }

    return (
        <div>
            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar com grupos de botões">
                <div class="btn-group" role="group" aria-label="Primeiro grupo">
                    <h2 id="tabelLabel" >Usuários</h2>
                </div>
                <form onSubmit={this.handleFilter} class="form-inline">
                    <div class="input-group">
                        <button className="btn btn-primary" onClick={() => this.props.history.push('/addUsuario')}>Novo</button>
                        <div class="input-group-prepend">
                            <span class="input-group-btn">
                                <input class="form-control ml-4" type="text" onKeyDown={this.handleKeyDown} onChange={this.handleChange} aria-describedby="btnGroupAddon2" placeholder="Digite o texto da busca..." name="filter" />
                                <button class="btn btn-primary" id="btnGroupAddon2" type="submit">
                                    <spin><FontAwesomeIcon icon={faSearch} /></spin> Busca
				                    </button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            {contents}
        </div>
    );
  }

    // Trata a solicitação Delete  para um usuario  
    handleDelete(id, nome) {
        if (!window.confirm("Deseja deletar o usuario: " + nome))
            return;
        else {
            fetch('api/Usuarios/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        listaUsuario: this.state.listaUsuario.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
            });
        }
    }

    handleEdit(id) {
        this.props.history.push("/editUsuario/" + id);
    }

    handleFilter(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const strf = data.get('filter').toString();
        this.setState({ strFilter: strf});
    }

    handleChange(e) {
        this.setState({ strFilter: e.target.value });
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            this.render();
        }
    }

    async populateData() {
        const response = await fetch('api/Usuarios');
        if (response.status == 401)
            this.props.history.push("/signin");
        let data = await response.json();
        const nivel = getUser().nivel;
        const useridlogin = getUser().id
        if (nivel === 'AVANCADO')
            data = data.filter(user => user.nivel != 'ADMIN')
        if (nivel === 'BASICO' || nivel === 'NORMAL')
            data = data.filter(user => user.id == useridlogin)
        this.setState({ listaUsuario: data, loading: false });
    }
}

export class UsuarioData {
    id;
    nome;
    cpf;
    sexo;
    datanascimento;
    email;
    whatsapp;
    telefone;
    endereco;
    cidade;
    uf;
    cep;
    obs;
}

