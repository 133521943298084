import React, { Component } from 'react';

// get our fontawesome imports
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../custom.css'
import { getUser } from '../services/auth';

export class Config extends Component {
    static displayName = Config.name;

  constructor(props) {
      super(props);
      const user = getUser();
      if (user.nivel != 'ADMIN' && user.nivel != 'AVANCADO') {
          window.confirm("Usuário não autorizado para efetuar esta operação.");
          this.props.history.goBack();
      }
      this.state = { listaConfig: [], strFilter: '', loading: true };
      // este binding é necessário para que o 'this' funcione no callback  
      this.handleDelete = this.handleDelete.bind(this);
      this.handleEdit = this.handleEdit.bind(this);
      this.handleFilter = this.handleFilter.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

    renderDataTable(listaConfig) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Parâmetro</th>
            <th>Valor</th>
            <th>Comandos</th>
          </tr>
        </thead>
        <tbody>
          {listaConfig.map(config =>
            <tr key={config.id}>
                <td>{config.parametro}</td>
                <td>{config.valor}</td>
                <td>
                  <button className="btn btn-edit btn-edit-l" onClick={() => this.handleEdit(config.id)}>Editar</button>  
                  <button className="btn btn-del btn-del-l" onClick={() => this.handleDelete(config.id, config.parametro)}>Deletar</button>
                </td>
            </tr>
          )}
        </tbody>
      </table>
    );
}

render() {
        let contents;
        if (this.state.loading) {
            contents = <p><em>Carregando...</em></p>
        } else {
            let filtered = this.state.listaConfig.filter((rec) => {
                return (rec.id.toString().indexOf(this.state.strFilter) != -1 ||
                    rec.parametro.indexOf(this.state.strFilter) != -1 ||
                    rec.valor.indexOf(this.state.strFilter) != -1
                );
            });
            contents = this.renderDataTable(filtered);
        }

    return (
        <div>
            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar com grupos de botões">
                <div class="btn-group" role="group" aria-label="Primeiro grupo">
                    <h2 id="tabelLabel" >Configurar Parâmetros do Sistema</h2>
                </div>
                <form onSubmit={this.handleFilter} class="form-inline">
                    <div class="input-group">
                        <button className="btn btn-primary" onClick={() => this.props.history.push('/addConfig')}>Novo</button>
                        <div class="input-group-prconfigend">
                            <span class="input-group-btn">
                                <input class="form-control ml-4" type="text" onKeyDown={this.handleKeyDown} onChange={this.handleChange} aria-describedby="btnGroupAddon2" placeholder="Digite o texto da busca..." name="filter" />
                                <button class="btn btn-primary" id="btnGroupAddon2" type="submit">
                                    <spin><FontAwesomeIcon icon={faSearch} /></spin> Busca
				                    </button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            {contents}
        </div>
    );
  }

    // Trata a solicitação Delete  para um paciente  
    handleDelete(id, param) {
        if (!window.confirm("Deseja deletar o parâmetro: " + id + " - " + param))
            return;
        else {
            fetch('api/config/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        listaConfig: this.state.listaConfig.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
            });
        }
    }

    handleEdit(id) {
        this.props.history.push("/editConfig/" + id);
    }

    handleFilter(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const strf = data.get('filter').toString();
        this.setState({ strFilter: strf});
    }

    handleChange(e) {
        this.setState({ strFilter: e.target.value });
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            this.render();
        }
    }

    async populateData() {
        const response = await fetch('api/config');
        if (response.status === 401)
            this.props.history.push("/signin");
        let data = await response.json();
        if (getUser().nivel === 'AVANCADO')
            data = data.filter(config => config.tipo == 'C')
        this.setState({ listaConfig: data, loading: false });
    }
}

export class ConfigData {
    id;
    parametro;
    valor;
}

