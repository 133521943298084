import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

//import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
//import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html';
import {loadMentions, mentions} from './mentions';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
//import mentions from './mentions';


export var Content;
export class WysiwygEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: this.updateContent(this.props.content),
        };
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    componentDidMount() {
        loadMentions();
    }

    updateContent(_content) {
        //let draft = (htmlToDraft(_content));
        //_content = "<p>Teste</p>";

        const blocksFromHtml = htmlToDraft(_content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return EditorState.createWithContent(contentState);


        //let html = (draftToHtml(convertToRaw(editorState.getCurrentContent())));
        //let html2 = stateToHTML(draft);
        // if (_content == html2)
        //   console.log("html ok");
        // return (EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(_content))));
    }

    onEditorStateChange(editorState) {
        this.setState(() => ({
            editorState,
        }));
        Content = editorState.getCurrentContent();
    };

    componentWillReceiveProps(nextProps) {
        Content = this.updateContent(nextProps.content);
        this.setState(() => ({
            editorState: Content
        }));
        /*if (content != this.state.editorState.getCurrentContent() && content != undefined) {
            content = this.updateContent(content);
            this.setState({ editorState: content });
        }*/
    }

    render() {
        const { editorState } = this.state;
        //return (<p>Teste de escrita</p>);
        return (
            <>
                <Editor
                    editorState={editorState}
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    onEditorStateChange={this.onEditorStateChange}
                    mention={{
                        separator: ' ',
                        trigger: '@',
                        suggestions: mentions,

                    }}
                    hashtag={{}}
                />
            </>
        );
    }
}

/*<textarea
    disabled
    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/>*/
/*
const EditorWithMentionHashtag = () => (
    <Editor
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        defaultEditorState={iniEditorContent}

        //contentState = "teste"
        //toolbarOnFocus
        mention={{
            separator: ' ',
            trigger: '@',
            suggestions: [
                { text: 'APPLE', value: 'apple', url: 'apple' },
                { text: 'BANANA', value: 'banana', url: 'banana' },
                { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                { text: 'DURIAN', value: 'durian', url: 'durian' },
                { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                { text: 'FIG', value: 'fig', url: 'fig' },
                { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
            ],
        }}
        hashtag={{}}
    />
)*/

/*import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
*/
