import React, { Component } from 'react';
//import { RouteComponentProps } from 'react-router';
//import { Link, NavLink } from 'react-router-dom';
import { PacienteData } from './Paciente';

export class AddPaciente extends Component{
    constructor(props) {
        super(props);
        //atualiza o state do componente
        this.state = { title: "", loading: true, pacData: new PacienteData() };
        //id do paciente
        var pacid = this.props.match.params["pacid"];
        // define o state para a edição de um contato
        if (pacid > 0) {
            this.populateData(pacid);
        }
        else // define o state para adição de contato
        {
            this.state = { title: "Criar Paciente", loading: false, pacData: new PacienteData() };
        }
        // este binding é necessário para fazer o 'this' funcionar no callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    render() {
        let conteudo = this.state.loading
            ? <p><em>Carregando...</em></p>
            : this.renderCreateForm();
        return <div>
            <h2>{this.state.title}</h2>
            <hr />
            {conteudo}
        </div>;
    }
    // trata o evento submit do formulario
    handleSave(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        var object = {};
        data.forEach(function (value, key) {
            //if (key.toLowerCase() == 'datanascimento')
             //   object[key] = new Date(value).toISOString();
            //else
            if(key.toLowerCase() === 'sexo')
                object[key] = value[0];
            else if (key.toLowerCase() !== 'id')
                object[key] = value;
        });
        var json = JSON.stringify(object);
        // PUT solicitação para editar paciente
        if(this.state.pacData.id) {
            json = json.replace("{", "{\"id\":" + this.state.pacData.id + ",");
            const path = 'api/Pacientes/' + this.state.pacData.id;
            try {
                fetch( path, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => console.log(response.json()))
                    .then((responseJson) => {
                        this.props.history.push("/paciente");
                    })
            } catch (e) { console.log(e); }
        }
        else // POST requisição para adicionar contato
        {
            try {
                fetch('api/Pacientes', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => console.log(response.json()))
                    .then((responseJson) => {
                        this.props.history.push("/paciente");
                    })
            } catch (e) {
                console.log(e);
            }
        }
    }
    // trata o evento do botão cancela
    handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/paciente");
    }
    // Retorna o formulario HTMl para o método Render
    renderCreateForm() {
         return (
            <form onSubmit={this.handleSave} >
                <div className="form-group row" >
                    <input type="hidden" name="Id" value={this.state.pacData.id} />
                </div>
                < div className="form-group row" >
                    <label className=" control-label col-md-12" htmlFor="nome">Nome</label>
                    <div className="col-md-6">
                        <input className="form-control" type="text" name="nome"
                            defaultValue={this.state.pacData.nome} required />
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="cpf" >CPF</label>
                    <div className="col-md-6">
                         <input className="form-control" type="number" name="cpf" pattern="[0-9]{11}"
                            defaultValue={this.state.pacData.cpf} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="sexo">Sexo</label>
                    <div className="col-md-6">
                        <select className="form-control" data-val="true" name="sexo"
                            defaultValue={this.state.pacData.sexo === "M" ? "Masculino" : "Feminino"} required>
                            <option value="">-- Selecione o Sexo  --</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Feminino">Feminino</option>
                        </select>
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="datanascimento" >Data de Nascimento</label>
                    <div className="col-md-6">
                        <input className="form-control" type="date" name="datanascimento"
                            defaultValue={this.state.pacData.datanascimento} required />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="email" >Email</label>
                    <div className="col-md-6">
                         <input className="form-control" type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            defaultValue={this.state.pacData.email}/>
                    </div>
                 </div>
                 < div className="form-group row" >
                     <label className=" control-label col-md-12" htmlFor="whatsapp">Whatsapp</label>
                     <div className="col-md-6">
                         <input className="form-control" type="tel" id="whats" name="whatsapp" pattern="\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|
2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|
4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$"
                             defaultValue={this.state.pacData.whatsapp} />
                     </div>
                 </div >
                < div className="form-group row" >
                    <label className=" control-label col-md-12" htmlFor="telefone">Telefone</label>
                    <div className="col-md-6">
                         <input className="form-control" type="tel" id="phone" name="telefone" pattern="\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|
2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|
4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$"
                            defaultValue={this.state.pacData.telefone}/>
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="endereco" >Endereco</label>
                    <div className="col-md-6">
                        <input className="form-control" type="text" name="endereco"
                            defaultValue={this.state.pacData.endereco} required />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="cidade">Cidade</label>
                    <div className="col-md-6">
                        <input className="form-control" type="text" name="cidade"
                            defaultValue={this.state.pacData.cidade} required />
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="uf" >UF</label>
                    <div className="col-md-6">
                        <input className="form-control" number name="uf"
                            defaultValue={this.state.pacData.uf} required />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="cep" >CEP</label>
                    <div className="col-md-6">
                         <input className="form-control" type="number" name="cep" pattern="[0-9]{8}"
                            defaultValue={this.state.pacData.cep} />
                    </div>
                </div>
                <div className="form-group row">
                     <label className="control-label col-md-12" htmlFor="obs" >Observações</label>
                     <div className="col-md-12">
                         <input className="form-control" type="text" name="obs"
                             defaultValue={this.state.pacData.obs} />
                     </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Salvar</button>
                    <button className="btn btn-primary" onClick={this.handleCancel}>Cancelar</button>
                </div >
            </form >
        );
    }

    async populateData(pacid) {
        const response = await fetch('api/Pacientes/' + pacid);
        const data = await response.json();
        this.setState({ title: "Editar Paciente", loading: false, pacData: data });
    }
}  