import { getUser } from "../services/auth";

export var idade = function idade(dtnascimento) {
    let hoje = new Date();
    let nascimento = new Date(dtnascimento);
    let diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
    if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
        new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
        diferencaAnos--;
    return (diferencaAnos);
}

export var formatDate = function formatDate(date) { // dd/mm/yy
    return (date.substr(-2) + "/" + date.substr(5, 2) + "/" + date.substr(2, 2));
}

export var formatDate4Y = function formatDate4Y(date) { // dd/mm/yyyy
    return (date.substr(-2) + "/" + date.substr(5, 2) + "/" + date.substr(0, 4));
}

export var today = function today() { // dd/mm/yyyy
    return formatDate4Y((new Date()).toISOString().substring(0, 10));
}

// adiciona evento de log
export var log = async function log(tipo, msg) {
    const data = new LogsData();
    data.data_hora = (new Date()).toISOString().substring(0, 19);
    const user = getUser();
    data.id_usuario = user.id;
    data.tipo = tipo;
    data.mensagem = msg;
    var json = JSON.stringify(data);
    // POST requisi��o para adicionar Log
    try {
        await fetch('api/Logs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: json,
        }).then((response) => response.json())
            .then((responseJson) => {
                this.props.history.push("/logs");
            })
    } catch (e) { console.log(e); }
    return true;
}

// get config registry by parameter
export var getConfigReg = async function getConfigReg(param) {
    const resconfig = await fetch('api/Config');
    const listConfig = await resconfig.json();
    for (var i = 0; i < listConfig.length; i++)
        if (listConfig[i].parametro.toLowerCase() === param.toLowerCase())
            return listConfig[i];
    return null
}

// get config value by parameter
export var getConfigValue = async function getConfigValue(param) {
    const resconfig = await fetch('api/Config');
    const listConfig = await resconfig.json();
    for (var i = 0; i < listConfig.length; i++)
        if (listConfig[i].parametro.toLowerCase() === param.toLowerCase())
            return listConfig[i].valor;
    return null
}

class LogsData {
    id;
    data_hora;
    usuario;
    tipo;
    mensagem;
}

/*exports.idade = idade; 
exports.formatDate = formatDate;
exports.log = log;*/