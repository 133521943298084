export var mentions = [];

export const loadMentions = async () => {
    const data = await fetch('api/Mentions');
    mentions = await data.json();
}

const mentioni = [
    {
        text: 'Matthew Russell',
        url: 'https://twitter.com/mrussell247',
        value: 'Teste de mention 11111111111111111111',
        //avatar: 'https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg',
    },
    {
        text: 'Julian Krispel-Samsel',
        value: 'Teste de mention 22222222222222222222',
        url: 'https://twitter.com/juliandoesstuff',
        //avatar: 'https://avatars2.githubusercontent.com/u/1188186?v=3&s=400',
    },
    {
        text: 'Jyoti Puri',
        value: 'Teste de mention 33333333333333333333',
        url: 'https://twitter.com/jyopur',
        //avatar: 'https://avatars0.githubusercontent.com/u/2182307?v=3&s=400',
    },
    {
        text: 'Max Stoiber',
        url: 'https://twitter.com/mxstbr',
        value: 'Teste de mention 44444444444444444444',
        //avatar: 'https://pbs.twimg.com/profile_images/763033229993574400/6frGyDyA_400x400.jpg',
    },
    {
        text: 'Nik Graf',
        url: 'https://twitter.com/nikgraf',
        value: 'Teste de mention 55555555555555555555',
        //avatar: 'https://avatars0.githubusercontent.com/u/223045?v=3&s=400',
    },
    {
        text: 'Pascal Brandt',
        url: 'https://twitter.com/psbrandt',
        value: 'Teste de mention 666666666666666666666',
        //avatar: 'https://pbs.twimg.com/profile_images/688487813025640448/E6O6I011_400x400.png',
    },
];

export const mention = [
    {
        name: 'Matthew Russell',
        link: 'https://twitter.com/mrussell247',
        value: 'Teste de mention 11111111111111111111',
        //avatar: 'https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg',
    },
    {
        name: 'Julian Krispel-Samsel',
        value: 'Teste de mention 22222222222222222222',
        link: 'https://twitter.com/juliandoesstuff',
        //avatar: 'https://avatars2.githubusercontent.com/u/1188186?v=3&s=400',
    },
    {
        name: 'Jyoti Puri',
        value: 'Teste de mention 33333333333333333333',
        link: 'https://twitter.com/jyopur',
        //avatar: 'https://avatars0.githubusercontent.com/u/2182307?v=3&s=400',
    },
    {
        name: 'Max Stoiber',
        link: 'https://twitter.com/mxstbr',
        value: 'Teste de mention 44444444444444444444',
        //avatar: 'https://pbs.twimg.com/profile_images/763033229993574400/6frGyDyA_400x400.jpg',
    },
    {
        name: 'Nik Graf',
        link: 'https://twitter.com/nikgraf',
        value: 'Teste de mention 55555555555555555555',
        //avatar: 'https://avatars0.githubusercontent.com/u/223045?v=3&s=400',
    },
    {
        name: 'Pascal Brandt',
        link: 'https://twitter.com/psbrandt',
        value: 'Teste de mention 666666666666666666666',
        //avatar: 'https://pbs.twimg.com/profile_images/688487813025640448/E6O6I011_400x400.png',
    },
];

export default mentions;
