import React, { Component } from 'react';
import { MentionsData } from './Mention';

export class AddMention extends Component{
    constructor(props) {
        super(props);
        //atualiza o state do componente
        this.state = { title: "", loading: true, mentData: new MentionsData };
        //id do exame padrao
        var mentid = this.props.match.params["mentionid"];
        // define o state para a edição de um ment
        if (mentid > 0) {
            this.populateData(mentid);
        }
        else // define o state para adição de ment
        {
            this.state = { title: "Criar Mentions", loading: false, mentData: new MentionsData };
        }
        // este binding é necessário para fazer o 'this' funcionar no callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    render() {
        let conteudo = this.state.loading
            ? <p><em>Carregando...</em></p>
            : this.renderCreateForm();
        return <div>
            <h2>{this.state.title}</h2>
            <hr />
            {conteudo}
        </div>;
    }
    // trata o evento submit do formulario
    handleSave(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        var object = {};
        data.forEach(function (value, key) {
            if (key.toLowerCase() != 'id')
                object[key] = value;
        });
        var json = JSON.stringify(object);
        // PUT solicitação para editar ment
        if(this.state.mentData.id) {
            json = json.replace("{", "{\"id\":" + this.state.mentData.id + ",");
            const path = 'api/Mentions/' + this.state.mentData.id;
            try {
                fetch( path, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => console.log(response.json()))
                    .then((responseJson) => {
                        this.props.history.push("/mention");
                    })
            } catch (e) { console.log(e); }
        }
        else // POST requisição para adicionar ment
        {
            try {
                fetch('api/Mentions', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => response.json())
                    .then((responseJson) => {
                        this.props.history.push("/mention");
                    })
            } catch (e) { console.log(e); }
        }
    }
    // trata o evento do botão cancela
    handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/mention");
    }
    // Retorna o formulario HTMl para o método Render
    renderCreateForm() {
         return (
            <form onSubmit={this.handleSave} >
                <div className="form-group row" >
                    <input type="hidden" name="Id" value={this.state.mentData.id} />
                </div>
                < div className="form-group row" >
                    <label className=" control-label col-md-12" htmlFor="text">Nome do Mention</label>
                    <div className="col-md-6">
                        <input className="form-control" type="text" name="text"
                            defaultValue={this.state.mentData.text} required />
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="value" >Texto</label>
                    <div className="col-md-6">
                         <input className="form-control" type="text" name="value"
                             defaultValue={this.state.mentData.value} required />
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Salvar</button>
                    <button className="btn btn-primary" onClick={this.handleCancel}>Cancelar</button>
                </div >
            </form >
        );
    }

    async populateData(mentid) {
        const response = await fetch('api/Mentions/' + mentid);
        const data = await response.json();
        this.setState({ title: "Editar Mention", loading: false, mentData: data });
    }
}  