//import React from "react";
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";


//import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Paciente } from './components/Paciente';
import { AddPaciente } from './components/AddPaciente';
import { Exame } from './components/Exame';
import { AddExame } from './components/AddExame';
import { LaudarExame } from './components/LaudarExame';
import { VideosExame } from './components/VideosExame';
import { SimpleEditor } from './components/SimpleEditor';
import { ExamePadrao } from './components/ExamePadrao';
import { AddExamePadrao } from './components/AddExamePadrao';
import { Mention } from './components/Mention';
import { AddMention } from './components/AddMention';
import { Config } from './components/Config';
import { AddConfig } from './components/AddConfig';
import { Usuario } from './components/Usuario';
import { AddUsuario } from './components/AddUsuario';
import { Logs } from './components/Logs';
import { SignIn } from './signin';
import { Logout } from "./components/Logout";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => 
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{ pathname: "./signin", state: { from: props.location } }} />
                )
        }
    />
);
/*const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} component={isAuthenticated ? Component : { SignIn }} />
    )

const PrivateRoute = props => {
    return isAuthenticated ? <Route {...props} /> : <Redirect to="/signin" />
}*/

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Layout>
            <Route exact path='/' component={Home} />
            <PrivateRoute path="/app" component={() => <h1>App</h1>} />
            <Route exact path='/exame/signin' component={SignIn} />
            <PrivateRoute path='/counter' component={Counter} />
            <Route path='/fetch-data' component={FetchData} />
            <PrivateRoute path='/paciente' component={Paciente} />
            <PrivateRoute path='/addPaciente' component={AddPaciente} />
            <PrivateRoute path='/editPaciente/:pacid' component={AddPaciente} />
            <PrivateRoute path='/examehist' component={Exame} />
            <Route path='/exame/:ativos' component={Exame} />
            <PrivateRoute path='/addExame' component={AddExame} />
            <PrivateRoute path='/editExame/:exameid' component={AddExame} />
            <PrivateRoute path='/laudarExame/:exameid' component={LaudarExame} />
            <PrivateRoute path='/videosExame/:exameid' component={VideosExame} />
            <PrivateRoute path='/simpleeditor' component={SimpleEditor} />
            <PrivateRoute path='/examePadrao' component={ExamePadrao} />
            <PrivateRoute path='/addExamePadrao' component={AddExamePadrao} />
            <PrivateRoute path='/editExamePadrao/:examepadraoid' component={AddExamePadrao} />
            <PrivateRoute path='/mention' component={Mention} />
            <PrivateRoute path='/addMention' component={AddMention} />
            <PrivateRoute path='/editMention/:mentionid' component={AddMention} />
            <PrivateRoute path='/config' component={Config} />
            <PrivateRoute path='/addConfig' component={AddConfig} />
            <PrivateRoute path='/editConfig/:configid' component={AddConfig} />
            <PrivateRoute path='/usuario' component={Usuario} />
            <PrivateRoute path='/addUsuario' component={AddUsuario} />
            <PrivateRoute path='/editUsuario/:userid' component={AddUsuario} />
            <PrivateRoute path='/logs' component={Logs} />
            <Route path='/signin' component={SignIn} />
            <Route path='/logout' component={Logout} />
            </Layout>
        </Switch>
    </BrowserRouter>
);

//<Route path="*" component={() => <h1>Page not found</h1>} />
export default Routes;