import React, { Component } from 'react';
//import { RouteComponentProps } from 'react-router';
//import { Link, NavLink } from 'react-router-dom';
import { UsuarioData } from './Usuario';
import { getUser } from '../services/auth';

export class AddUsuario extends Component{
    constructor(props) {
        super(props);
        //atualiza o state do componente
        this.state = { title: "", loading: true, userData: new UsuarioData() };
        //id do usuario
        var userid = this.props.match.params["userid"];
        // define o state para a edição de um contato
        if (userid > 0) {
            this.populateData(userid);
        }
        else // define o state para adição de contato
        {
            this.state = { title: "Criar Usuário", loading: false, userData: new UsuarioData() };
        }
        // este binding é necessário para fazer o 'this' funcionar no callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    render() {
        let conteudo = this.state.loading
            ? <p><em>Carregando...</em></p>
            : this.renderCreateForm();
        return <div>
            <h2>{this.state.title}</h2>
            <hr />
            {conteudo}
        </div>;
    }
    // trata o evento submit do formulario
    handleSave(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        var object = {};
        data.forEach(function (value, key) {
            if (key.toLowerCase() !== 'id')
                object[key] = value;
        });
        var json = JSON.stringify(object);
        // PUT solicitação para editar usuario
        if(this.state.userData.id) {
            json = json.replace("{", "{\"id\":" + this.state.userData.id + ",");
            const path = 'api/Usuarios/' + this.state.userData.id;
            try {
                fetch( path, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => console.log(response.json()))
                    .then((responseJson) => {
                        this.props.history.push("/usuario");
                    })
            } catch (e) { console.log(e); }
        }
        else // POST requisição para adicionar contato
        {
            try {
                fetch('api/Usuarios', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => response.json())
                    .then((responseJson) => {
                        this.props.history.push("/usuario");
                    })
            } catch (e) { console.log(e); }
        }
    }
    // trata o evento do botão cancela
    handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/usuario");
    }

    // Retorna o formulario HTMl para o método Render
    renderCreateForm() {
         //this.loadComboNivel();
         return (
            <form onSubmit={this.handleSave} >
                <div className="form-group row" >
                    <input type="hidden" name="Id" value={this.state.userData.id} />
                </div>
                < div className="form-group row" >
                    <label className=" control-label col-md-12" htmlFor="nome">Nome</label>
                    <div className="col-md-6">
                        <input className="form-control" type="text" name="nome"
                            defaultValue={this.state.userData.nome} required />
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="nivel" >Nível</label>
                    <div className="col-md-6">
                         <select className="form-control" id="cboNivel" name="nivel"
                             defaultValue={this.state.userData.nivel} required>
                             <option value="BASICO">BÁSICO</option>
                             <option value="NORMAL">NORMAL</option>
                             <option value="AVANCADO">AVANÇADO</option>
                             <option value="ADMIN">ADMIN</option>
                         </select>
                    </div>
                 </div>

                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="login">Login</label>
                    <div className="col-md-6">
                        <input className="form-control" type="text" name="login"
                            defaultValue={this.state.userData.login} required />
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="senha" >Senha</label>
                    <div className="col-md-6">
                         <input className="form-control" type="password" name="senha"
                            defaultValue={this.state.userData.senha} required />
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Salvar</button>
                    <button className="btn btn-primary" onClick={this.handleCancel}>Cancelar</button>
                </div >
            </form >
        );
    }

    async populateData(userid) {
        const response = await fetch('api/Usuarios/' + userid);
        const data = await response.json();        
        this.setState({ title: "Editar Usuário", loading: false, userData: data });

        var op = document.getElementById("cboNivel").getElementsByTagName("option");
        const nivel = getUser().nivel 
        for (var i = 0; i < op.length; i++) {
            // lowercase comparison for case-insensitivity
            if (op[i].value.toUpperCase() === "ADMIN" && (nivel === 'AVANCADO' || nivel === 'BASICO' || nivel === 'NORMAL'))
                op[i].disabled = true;
            if (op[i].value.toUpperCase() === "AVANCADO" && (nivel === 'BASICO' || nivel === 'NORMAL'))
                op[i].disabled = true;
            if (op[i].value.toUpperCase() === "NORMAL" && nivel === 'BASICO')
                op[i].disabled = true;
        }
    }
}  