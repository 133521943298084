import React, { Component } from 'react';
//import { EditorState } from 'draft-js';
//import Editor from 'draft-js-plugins-editor';
//import { Editor } from 'react-draft-wysiwyg';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import editorStyles from './editorStyles.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import mentions from './mentions';

//import { EditorState, ContentState, convertFromHTML } from 'draft-js'
//import { stateToHTML } from 'draft-js-export-html';

//////////////////////////////////////////////////////////
//import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
//import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html';

var content;
export class SimpleEditor extends Component {
    constructor(props) {
        super(props);
        this.content = "Teste...";
        this.state = {
            editorState: this.updateContent(this.content),
            suggestions: mentions,
        };
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.mentionPlugin = createMentionPlugin();
    }

    updateContent(_content) {
        return (EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(_content))));
    }

    onEditorStateChange(editorState) {
        this.setState(() => ({
            editorState,
        }));
        content = editorState.getCurrentContent();
    };

    componentWillReceiveProps() {
        content = this.updateContent(this.props.content);
        this.setState(() => ({
            editorState: content
        }));
        /*if (content != this.state.editorState.getCurrentContent() && content != undefined) {
            content = this.updateContent(content);
            this.setState({ editorState: content });
        }*/
    }

    onChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    onSearchChange = ({ value }) => {
        this.setState({
            suggestions: defaultSuggestionsFilter(value, mentions),
        });
    };

    onAddMention = (value) => {
        // get the mention object selected
        //var x = value;
        value.name = value.value;
    }

    focus = () => {
        //this.editor.focus();
    };

    render() {
        const { MentionSuggestions } = this.mentionPlugin;
        const plugins = [this.mentionPlugin];

        //const { editorState } = this.state;

        return (
            <div className={editorStyles.editor} onClick={this.focus}>
            <Editor
                editorState={this.state.editorState}
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={this.onEditorStateChange}
                hashtag={{}}

                //onChange={this.onChange}
                plugins={plugins}
                ref={(element) => { this.editor = element; }}
            />
            <MentionSuggestions
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
                onAddMention={this.onAddMention}
            />
            </div>
        );
    }
}

///////////////////////////////////////////////////////////
