import React, { Component } from 'react';
import { logout } from '../services/auth';

export class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = { title: "Logout" };
        this.doLogout();
    }

    render() {
        let conteudo = <p><em>Fazendo Logout...</em></p>
        return <div>
            <h2>{this.state.title}</h2>
            <hr />
            {conteudo}
        </div>;
    }

    doLogout() {
        logout();
        this.props.history.push("/");
    }
}