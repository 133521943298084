import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../custom.css';
import LogoBS from "../img/logoBabyShow.png";
import Whatsapp from "../img/whatsapp.png";
import Email from "../img/email.png";

export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
          <div className="home-image">
              <div className="right-image">
                  <a href="https://www.docdocs.com.br/assets/videoBabyShow.mp4" target="_blank"><img src={LogoBS} alt="BabyShow" /></a>
              </div>
              <div className="home-text">
                <br /><br /><br /><br />
                <h1>Doctor's Documents !</h1>
                <br />
                <h3>{"Organização e gerenciamento da documentação médica."}</h3>
                  <br /><br /><br />
              </div>
              <div className="bottom-left-text">
                  <h4><a href="https://wa.me/5551993364556" target="_blank"><img src={Whatsapp} alt="Whatsapp" height="30" width="30" /><b>{" +55(51)99336-4556"}</b></a></h4>
                  <h4><a href="mailto:contato@docdocs.com.br?Subject=Contato%20do%20Site"><img src={Email} alt="Email" height="25" width="35" /><b>{"contato@docdocs.com.br"}</b></a></h4>
              </div>              
        </div>
    );
  }
}

ReactDOM.render(Home, document.getElementById('root'));