import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, NavbarText } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from "../img/docdocslogosmall.png";
import { faUser } from "@fortawesome/free-solid-svg-icons"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './NavMenu.css';
import './NavMenu.scss';
import { getConfigValue } from './Util';
import { getCompany, getUser, isAuthenticated } from '../services/auth';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    var disableItemMenuLaudario;
    this.disableItemMenuLaudario = this.statusItemMenuLaudario();
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
   }

    async statusItemMenuLaudario() {
        return await getConfigValue('laudario') == "true" ? '' : 'disabled';
    }

    toggleNavbar(e) {
        e.preventDefault();
        console.log(this.state.collapsed)
        this.setState({
          collapsed: !this.state.collapsed
        });
    }

    render() {
        const user = getUser();
        const entrar = isAuthenticated() ? " "+user.login : ' Entrar'
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-0 py-0" light>
          <Container>
            <NavbarBrand tag={Link} to="/"><a><img src={Logo} alt="DocDocs" /></a></NavbarBrand>
            <NavbarText className="company-nav text-dark" htmlFor="mycia">{getCompany()}</NavbarText>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />                    
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem>
                <UncontrolledDropdown className="dropdown" >
                    <DropdownToggle color="secundary" className="dropbtn" caret>
                        Cadastros
                    </DropdownToggle>
                        <DropdownMenu className="dropdown-menu">
                            <DropdownItem tag={Link} className="text-dark" to="/examePadrao">Exames Padrão</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem tag={Link} className="text-dark" to="/mention">Mentions</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem tag={Link} className="text-dark" to="/paciente">Pacientes</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem tag={Link} className="text-dark" to="/usuario">Usuários</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem tag={Link} className="text-dark" to="/logs">Logs</DropdownItem>
                        </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown className="dropdown" >
                    <DropdownToggle color="secundary" className="dropbtn" caret>
                                Exames
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu">
                        <DropdownItem tag={Link} className="text-dark" to="/exame/1">Ativos</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem tag={Link} className="text-dark" to="/examehist">Histórico</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/config">Configurar</NavLink>
                </NavItem>
                <UncontrolledDropdown className="dropdown" >
                    <DropdownToggle color="secundary" className="dropbtn" caret><spin><FontAwesomeIcon icon={faUser} /></spin>{entrar}</DropdownToggle>
                    <DropdownMenu className="dropdown-menu">
                        <DropdownItem tag={Link} className="text-dark" to="/signin">Login</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem tag={Link} className="text-dark" to="/logout">Logout</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
/*
                 <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                </NavItem>

 */