import React, { Component } from 'react';
//import { RouteComponentProps } from 'react-router';
//import { Link, NavLink } from 'react-router-dom';
import { ExameData } from './Exame';
import { PacienteData } from './Paciente';
import { ExamePadraoData } from './ExamePadrao';
import { log, idade, formatDate } from './Util';


import { WysiwygEditor, Content } from './WysiwygEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { getUser } from '../services/auth';
//import htmlToDraft from 'html-to-draftjs';

export class LaudarExame extends Component{
    constructor(props) {
        super(props);
        //atualiza o state do componente
        this.state = {
            title: "Laudo Médico", loading: true,
            exameData: new ExameData(), pacienteData: new PacienteData(), epData: new ExamePadraoData()
        };
        //id do exame
        var exameid = this.props.match.params["exameid"];
        this.diag = "";
        // Salva (false) ou confirma e efetiva (true) laudo
        this.confirm = false;
        // carrega o state com dados para a edição
        this.populateData(exameid);
        // este binding é necessário para fazer o 'this' funcionar no callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.resetLaudo = this.resetLaudo.bind(this);
    }

    render() {
        let conteudo = this.state.loading
            ? <p><em>Carregando...</em></p>
            : this.renderDataTable();
        return <div>
            <h2>{this.state.title}</h2>
            {conteudo}
        </div>;
    }

    resetLaudo(e) {
        e.preventDefault();
        //const editor = document.getElementById("editorControl");

        let dataExam = this.state.exameData;
        dataExam.diagnostico = this.formatDiagnostico(true);
        this.setState(() => ({ exameData: dataExam }));
        //this.editor(true);
    }

    formatDiagnostico(reset) {
        if (this.state.exameData.diagnostico !== "" && this.state.exameData.diagnostico !== null && reset !== true)
            return (this.state.exameData.diagnostico);
        return (//"<p style=\"text-align:start;\">&nbsp;</p>" +
                 //"<p style=\"text-align:left;\"></p>"+
                //"<p></p>"+
                "<div style=\"text-align:none;\"><img src=\"http://teste.docdocs.com.br/assets/headerreport.jpg\" alt=\"undefined\" style=\"height: auto;width: auto\"/></div>"+
                "<p style =\"text-align:start;\"><span style=\"color: rgb(33,37,41);font-size: 16px;font-family: Arial, Helvetica, sans-serif;\">" +
                "<strong>          Nome:</strong> " + this.state.pacienteData.nome + "</span></p>" +
                "<p style =\"text-align:start;\"><span style=\"color: rgb(33,37,41);font-size: 16px;font-family: Arial, Helvetica, sans-serif;\">" +
                "<strong>          Nº Exame:</strong> " + this.state.exameData.id + "</span></p>" +
                "<p style =\"text-align:start;\"><span style=\"color: rgb(33,37,41);font-size: 16px;font-family: Arial, Helvetica, sans-serif;\">" +
                "<strong>          Exame:</strong> " + this.state.epData.nome + "</span></p>" +
                "<p style =\"text-align:start;\"><span style=\"color: rgb(33,37,41);font-size: 16px;font-family: Arial, Helvetica, sans-serif;\">" +
                "<strong>          Data:</strong> " + formatDate(this.state.exameData.data) + "</span></p>" +
                "<p style =\"text-align:center;\"><span style=\"color: rgb(33,37,41);font-size: 18px;font-family: Arial, Helvetica, sans-serif;\">" +
                "<strong>Interpretação</strong></span>&nbsp;</p>" +
                "<p></p>"+
                "<div style=\"text-align:none;\"><img src=\"http://teste.docdocs.com.br/assets/footerreport.jpg\" alt=\"undefined\" style=\"height: auto;width: auto\"/></div>"+
                "<p></p>");
    }

    editor(reset) {
        return (<WysiwygEditor id="editorControl" content={this.formatDiagnostico(reset)}/>);
    }

    // trata o evento do botão cancela
    handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/exame/1");
    }

    // trata o evento do botão confirma
    handleConfirm(e) {
        e.preventDefault();
        this.confirm = true;
        this.handleSave(e);
    }

    // trata o evento salvar registro
    handleSave(e) {
        e.preventDefault();
        const regExam = this.state.exameData;
        regExam.diagnostico = draftToHtml(convertToRaw(Content));
        var json = JSON.stringify(regExam);
        // PUT solicitação para editar exame
        if (this.state.exameData.id) {
            json = json.replace("{", "{\"id\":" + this.state.exameData.id + ",");
            json = json.replace("}", ",\"id_user_laudo\":"+getUser().id+",\"laudado\":" + this.confirm + "}");
            this.confirm = false;
            try {
                fetch('api/Exames/' + this.state.exameData.id, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => log("EDIT", "Alterou diag Exame: " + json))
                    .then((responseJson) => {
                        this.props.history.push("/exame/1");
                    })
            } catch (e) { console.log(e); }
        }
    }

    renderDataTable() {
        const regExam = this.state.exameData;
        const regPac = this.state.pacienteData;
        const regEP = this.state.epData;
        return (
            <>
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Exame</th>
                        <th>Descrição</th>
                        <th>Data</th>
                        <th>Paciente</th>
                        <th>Idade</th>
                        <th>Sexo</th>
                        <th>Convênio</th>
                        <th>Solicitante</th>
                    </tr>
                </thead>
                <tbody>
                    {
                         <tr key={regExam.id}>
                            <td>{regExam.id}</td>
                            <td>{regEP.nome}</td>
                            <td>{regExam.descricao}</td>
                            <td>{formatDate(regExam.data)}</td>
                            <td>{regPac.nome}</td>
                            <td>{idade(regPac.datanascimento)}</td>
                            <td>{regPac.sexo}</td>
                            <td>{regExam.convenio}</td>
                            <td>{regExam.solicitante}</td>
                        </tr>
                    }
                </tbody>
            </table>
            <div>
                {this.editor(false)}
                {this.renderCreateForm()}
            </div>
        </>
        );
    }

renderCreateForm() {
    return (
        <div className="form-group row" >
            <div className="col-md-6">
                <div className="form-group">
                    <button type="submit" className="btn btn-primary" onClick={this.handleConfirm}>Confirmar</button>
                    <button type="submit" className="btn btn-primary" onClick={this.handleSave}>Salvar</button>
                    <button className="btn btn-primary" onClick={this.handleCancel}>Cancelar</button>
                    <button className="btn btn-primary" onClick={this.resetLaudo}>Reiniciar</button>
                </div >
            </div >
        </div>
    );
}

    async populateData(exid) {
        let response = await fetch('api/Exames/' + exid);
        const dataExam = await response.json();
        response = await fetch('api/Pacientes/' + dataExam.id_paciente.toString());
        const dataPac = await response.json();
        response = await fetch('api/ExamePadrao/' + dataExam.id_exame_padrao.toString());
        const dataEP = await response.json();
        this.setState(() => ({
            title: "Laudo Médico", loading: false,
            exameData: dataExam, pacienteData: dataPac, epData: dataEP
        }));
    }
}  