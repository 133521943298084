import React, { Component } from 'react';
//import { RouteComponentProps } from 'react-router';
//import { Link, NavLink } from 'react-router-dom';
import { ExamePadraoData } from './ExamePadrao';

export class AddExamePadrao extends Component{
    constructor(props) {
        super(props);
        //atualiza o state do componente
        this.state = { title: "", loading: true, epData: new ExamePadraoData() };
        //id do exame padrao
        var epid = this.props.match.params["examepadraoid"];
        // define o state para a edição de um ep
        if (epid > 0) {
            this.populateData(epid);
        }
        else // define o state para adição de ep
        {
            this.state = { title: "Criar Exame Padrao", loading: false, epData: new ExamePadraoData() };
        }
        // este binding é necessário para fazer o 'this' funcionar no callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    render() {
        let conteudo = this.state.loading
            ? <p><em>Carregando...</em></p>
            : this.renderCreateForm();
        return <div>
            <h2>{this.state.title}</h2>
            <hr />
            {conteudo}
        </div>;
    }
    // trata o evento submit do formulario
    handleSave(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        var object = {};
        data.forEach(function (value, key) {
            if (key.toLowerCase() !== 'id')
                object[key] = value;
        });
        var json = JSON.stringify(object);
        // PUT solicitação para editar ep
        if(this.state.epData.id) {
            json = json.replace("{", "{\"id\":" + this.state.epData.id + ",");
            const path = 'api/ExamePadrao/' + this.state.epData.id;
            try {
                fetch( path, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => console.log(response.json()))
                    .then((responseJson) => {
                        this.props.history.push("/examePadrao");
                    })
            } catch (e) { console.log(e); }
        }
        else // POST requisição para adicionar ep
        {
            try {
                fetch('api/ExamePadrao', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => response.json())
                    .then((responseJson) => {
                        this.props.history.push("/examePadrao");
                    })
            } catch (e) { console.log(e); }
        }
    }
    // trata o evento do botão cancela
    handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/examePadrao");
    }
    // Retorna o formulario HTMl para o método Render
    renderCreateForm() {
         return (
            <form onSubmit={this.handleSave} >
                <div className="form-group row" >
                    <input type="hidden" name="Id" value={this.state.epData.id} />
                </div>
                < div className="form-group row" >
                    <label className=" control-label col-md-12" htmlFor="nome">Nome do Exame</label>
                    <div className="col-md-6">
                        <input className="form-control" type="text" name="nome"
                            defaultValue={this.state.epData.nome} required />
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="categoria" >Categoria</label>
                    <div className="col-md-6">
                         <input className="form-control" type="text" name="categoria"
                             defaultValue={this.state.epData.categoria} required />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="descricao">Descricao</label>
                    <div className="col-md-6">
                         <input className="form-control" type="text" name="descricao"
                             defaultValue={this.state.epData.descricao} />
                    </div>
                </div >
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Salvar</button>
                    <button className="btn btn-primary" onClick={this.handleCancel}>Cancelar</button>
                </div >
            </form >
        );
    }

    async populateData(epid) {
        const response = await fetch('api/ExamePadrao/' + epid);
        const data = await response.json();
        this.setState({ title: "Editar Exame Padrao", loading: false, epData: data });
    }
}  