
import React, { Component } from "react";

import Logo from "../img/docdocslogo.png";
import { login } from "../services/auth";
import api from "../services/api";

import { Form, Container } from "./styles";

export class SignIn extends Component {
    state = {
        Login: "",
        Senha: "",
        error: ""
    };
    
    handleSignIn = async e => {
        e.preventDefault();
        const { Login, Senha } = this.state;
        if (!Login || !Senha) {
            this.setState({ error: "Preencha usuário e senha para continuar!" });
        } else {
            //fetchregister();
            const options = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ Login, Senha })}
            const response = await fetch('api/Usuarios/login', options);
            //const response = await api.post("/api/Usuarios/login", { Login, Senha });
            if (response.status == 201 || response.status == 200) {
                const data = await response.json();
                login(data.user, data.token);
                this.props.history.push("/");
            } else
                this.setState({ error: "Houve um problema com o login, verifique suas credenciais. " + response.status});
        }
    };

    /*handleSignIn = async e => {
        e.preventDefault();
        const { Login, Senha } = this.state;
        if (!Login || !Senha) {
            this.setState({ error: "Preencha usuário e senha para continuar!" });
        } else {
            try {
                const response = await api.post("/api/Usuarios/login", { Login, Senha });
                login(response.data.token);
                this.props.history.push("/");
            } catch (err) {
                this.setState({
                    error:
                        "Houve um problema com o login, verifique suas credenciais."
                });
            }
        }
    };*/

    render() {
        return (
            <Container>
                <Form onSubmit={this.handleSignIn}>
                    <img src={Logo} alt="DocDocs logo" />
                    {this.state.error && <p>{this.state.error}</p>}
                    <input
                        type="text"
                        placeholder="Usuário"
                        onChange={e => this.setState({ Login: e.target.value })}
                    />
                    <input
                        type="password"
                        placeholder="Senha"
                        onChange={e => this.setState({ Senha: e.target.value })}
                    />
                    <button type="submit">Entrar</button>
                    <hr />
                </Form>
            </Container>
        );
    }
}

//export default withRouter(SignIn);