/*import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Paciente } from './components/Paciente';
import { AddPaciente } from './components/AddPaciente';
import { Exame } from './components/Exame';
import { AddExame } from './components/AddExame';
import { LaudarExame } from './components/LaudarExame';
import { VideosExame } from './components/VideosExame';
import { SimpleEditor } from './components/SimpleEditor';
import { ExamePadrao } from './components/ExamePadrao';
import { AddExamePadrao } from './components/AddExamePadrao';
import { Mention } from './components/Mention';
import { AddMention } from './components/AddMention';
import { Config } from './components/Config';
import { AddConfig } from './components/AddConfig';
import { Usuario } from './components/Usuario';
import { AddUsuario } from './components/AddUsuario';
import { Logs } from './components/Logs';
import { SignIn } from './signin';


import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/paciente' component={Paciente} />
        <Route path='/addPaciente' component={AddPaciente} />
        <Route path='/editPaciente/:pacid' component={AddPaciente} />
        <Route path='/exame' component={Exame} />
        <Route path='/addExame' component={AddExame} />
        <Route path='/editExame/:exameid' component={AddExame} />
        <Route path='/laudarExame/:exameid' component={LaudarExame} />
        <Route path='/videosExame/:exameid' component={VideosExame} />
        <Route path='/simpleeditor' component={SimpleEditor} />
        <Route path='/examePadrao' component={ExamePadrao} />
        <Route path='/addExamePadrao' component={AddExamePadrao} />
        <Route path='/editExamePadrao/:examepadraoid' component={AddExamePadrao} />
        <Route path='/mention' component={Mention} />
        <Route path='/addMention' component={AddMention} />
        <Route path='/editMention/:mentionid' component={AddMention} />
        <Route path='/config' component={Config} />
        <Route path='/addConfig' component={AddConfig} />
        <Route path='/editConfig/:configid' component={AddConfig} />
        <Route path='/usuario' component={Usuario} />
        <Route path='/addUsuario' component={AddUsuario} />
        <Route path='/editUsuario/:userid' component={AddUsuario} />
        <Route path='/logs' component={Logs} />
        <Route path='/signin' component={SignIn} />
     </Layout>
    );
  }
}*/
import React from "react";
import Routes from "./routes";

const App = () => <Routes />;
export default App;