import React, { Component } from 'react';

import { log, idade, formatDate, getConfigValue, getConfigReg } from './Util';
import { getUser } from '../services/auth';

// get our fontawesome imports
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../custom.css'

export class Exame extends Component {
    static displayName = Exame.name;

    constructor(props) {
        super(props);
        var baby_show;
        this.baby_show = " ";
        var laudario;
        this.laudario = " ";
        var ativos;
        this.ativos= this.props.match.params["ativos"] == 1;
        this.state = { listaExame: [], strFilter: '', loading: true, exameAtual: 0};
        // este binding é necessário para que o 'this' funcione no callback  
        this.handleDelete = this.handleDelete.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleLaudar = this.handleLaudar.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleVideos = this.handleVideos.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    renderDataTable(listaExame) {
        console.log("List Exame Filtered: " + listaExame);
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Comandos</th>
                        <th>Paciente</th>
                        <th>Exame</th>
                        <th>Id</th>
                        <th>Descrição</th>
                        <th>Data</th>
                        <th>Idade</th>
                        <th>Sexo</th>
                        <th>Convênio</th>
                        <th>Solicitante</th>
                        <th>Resp.</th>
                        <th>Status</th>
                        <th>Gravar</th>
                    </tr>
                </thead>
                <tbody>
                    {listaExame.map(reg => 
                        <tr key={reg.id}>
                            <td>
                                <button className="btn btn-laudar" style={{ display:  this.laudario }} title="Laudar" onClick={() => this.handleLaudar(reg.id)}>L</button>
                                <button className="btn btn-videos" style={{display: this.baby_show}} title="Mídias" onClick={() => this.handleVideos(reg.id)}>M</button>
                                <button className="btn btn-edit" style={{ display: (reg.diagnostico || reg.laudado || reg.envio >= 4 ) && this.state.exameAtual != reg.id ? 'none' : 'inline' }} title="Editar" onClick={() => this.handleEdit(reg.id)}>E</button>
                                <button className="btn btn-del" style={{ display: reg.diagnostico || reg.laudado || reg.envio >= 4 ? 'none' : 'inline' }} title="Deletar" onClick={() => this.handleDelete(reg.id, reg.nome_exame + ' de ' + reg.nome_paciente)}>D</button>
                                <button className="btn btn-print" style={{ display: (reg.laudado && this.laudario == 'inline') ? 'inline' : 'none' }} title="Imprimir" onClick={() => this.handlePrint(reg.id)}>I</button>
                            </td>
                            <td>{reg.nome_paciente + " - " + reg.id_paciente}</td>
                            <td>{reg.nome_exame}</td>
                            <td>{reg.id}</td>
                            <td>{reg.descricao}</td>
                            <td>{formatDate(reg.data)}</td>
                            <td>{idade(reg.data_nascimento)}</td>
                            <td>{reg.sexo}</td>
                            <td>{reg.convenio}</td>
                            <td>{reg.solicitante}</td>
                            <td>{reg.id_user_laudo}</td>
                            <td>{(reg.laudado || reg.envio >= 4 ? "Concluso" : "Aberto") + (this.state.exameAtual == reg.id ? " em andamento" : "")}</td>
                            <td>
                                <button className="btn btn-checked" style={{ display: this.state.exameAtual != reg.id ? 'none' : 'inline' }} title="Gravar" onClick={() => this.handleCheckChange(reg.id)}>X</button>
                                <button className="btn btn-unchecked" style={{ display: this.state.exameAtual == reg.id ? 'none' : 'inline' }} title="Gravar" onClick={() => this.handleCheckChange(reg.id)}> </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents;
        if (this.state.loading) {
            contents = <p><em>Carregando...</em></p>
        } else {
            let filtered = this.state.listaExame.filter((rec) => {
                return (
                    (rec.nome_paciente + " - " + rec.id_paciente).indexOf(this.state.strFilter) != -1 ||
                    rec.nome_exame.indexOf(this.state.strFilter) != -1 ||
                    rec.id.toString().indexOf(this.state.strFilter) != -1 ||
                    rec.descricao.indexOf(this.state.strFilter) != -1 ||
                    formatDate(rec.data).indexOf(this.state.strFilter) != -1 ||
                    rec.sexo.indexOf(this.state.strFilter) != -1 ||
                    rec.solicitante.indexOf(this.state.strFilter) != -1 ||
                    rec.convenio.indexOf(this.state.strFilter) != -1 ||
                    ((rec.laudado || rec.envio >= 4 ? "Concluso" : "Aberto") +
                        (this.state.exameAtual == rec.id ? " em andamento" : "")).indexOf(this.state.strFilter) != -1 ||
                    idade(rec.data_nascimento).toString().indexOf(this.state.strFilter) != -1
                );
            });
            contents = this.renderDataTable(filtered);
        }

        return (
            <div>
                <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar com grupos de botões">
                    <div class="btn-group" role="group" aria-label="Primeiro grupo">
                        <h2 id="tabelLabel" >Exames</h2>
                    </div>
                    <form onSubmit={this.handleFilter} class="form-inline">
                        <div class="input-group">
                            <button className="btn btn-primary" onClick={() => this.props.history.push('/addExame')}>Novo</button>
                            <div class="input-group-prepend">
                                <span class="input-group-btn">
                                    <input class="form-control ml-4" type="text" onKeyDown={this.handleKeyDown} onChange={this.handleChange} aria-describedby="btnGroupAddon2" placeholder="Digite o texto da busca..." name="filter" />
                                    <button class="btn btn-primary" id="btnGroupAddon2" type="submit">
                                        <spin><FontAwesomeIcon icon={faSearch} /></spin> Busca
				                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
                {contents}
            </div>
        );
    }

    handleDelete(id, nome) {
        if (!window.confirm("Deseja deletar o exame: " + id + " - " + nome))
            return;
        else {
            fetch('api/Exames/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        listaExame: this.state.listaExame.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
                require('./Util.js').log("DELETE", "Excluiu reg Exame: " + id + " - " + nome);
            });
        }
    }

    handlePrint(id) {
        window.confirm("Função ainda não implementada\n" + "Exame: " + id);
    }

    handleCheckChange(id) {
        let text = '' 
        const user = getUser()
        if (user == null) {
            this.props.history.push("/signin");
            return
        }
        if (user.nivel === 'BASICO') {
            window.confirm('Usuário não autorizado para efetuar esta operação !')
            return
        }
        if (this.state.exameAtual == id) {
            text = "Desativa modo gravação do exame " + id + " ?"
            id = 0
        }
        else {
            text = "Ativa modo gravação para o exame " + id + " ?"
        }
        if (window.confirm(text)) {
            this.updateExameAtual(String(id));
        }
    }

    handleEdit(id) {
        this.props.history.push("/editExame/" + id);
    }

    handleLaudar(id) {
        this.props.history.push("/laudarExame/" + id);
    }

    handleVideos(id) {
        this.props.history.push("/videosExame/" + id);
    }

    handleFilter(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const strf = data.get('filter').toString();
        this.setState({ strFilter: strf });
    }

    handleChange(e) {
        this.setState({ strFilter: e.target.value });
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            this.render();
        }
    }

    // Atualiza exame atual
    async updateExameAtual(idExameNovo) {
        const dataconfig = await getConfigReg('exame_atual')
        var jsondata = ""
        jsondata = "{\"id\":" + dataconfig.id + "," + "\"parametro\":\"" + dataconfig.parametro + "\",\"valor\":\"" + idExameNovo + "\",\"tipo\":\"S\"}";
        const path = 'api/Config/' + dataconfig.id;

        try {
            fetch(path, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: jsondata,
            }).then((response) => log("EDIT", "Alterou reg Config: " + jsondata))
                .then((responseJson) => {
                    this.setState({ exameAtual: parseInt(idExameNovo) });
                    //this.props.history.push("/exame");
                })
        } catch (e) { console.log(e); }
    }

    async populateData() {
        let response;
        if(this.ativos)
            response = await fetch('api/Exames/Paciente/Ativos');
        else
            response = await fetch('api/Exames/Paciente/Hist');
        if (response.status == 401)
            this.props.history.push("../signin");
        const data = await response.json();
        this.baby_show = await getConfigValue('baby_show') == "true" ? 'inline' : 'none';
        this.laudario = await getConfigValue('laudario') == "true" ? 'inline' : 'none';
        this.setState({ listaExame: data, loading: false, exameAtual: await getConfigValue('exame_atual') });
        console.log("List Exame: " + data);
        console.log("Exame atual: " + this.state.exameAtual);
    }
}

export class ExameData {
    id;
    id_paciente;
    id_exame_padrao;
    descricao;
    diagnostico;
    data;
    convenio;
    solicitante;
    id_user_laudo;
    laudado;
    msg_abertura;
    msg_fechamento;
    audio;
    constructor(props) {
        this.data = (new Date()).toISOString().substring(0, 10);
        this.msg_abertura = 'Oi pessoal !|Estou aqui na barriga da mamãe !|Prestem atenção...|Eu vou me apresentar...|Olha eu aí...'
        this.msg_fechamento = 'Por enquanto é isso pessoal !|Espero que tenham gostado...|Logo estaremos juntos...|Me aguardem !|Estou chegando...'
    }
}