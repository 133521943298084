import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ConfigData } from './Config';

export class AddConfig extends Component{
    constructor(props) {
        super(props);
        //atualiza o state do componente
        this.state = { title: "", loading: true, configData: new ConfigData };
        //id do exame padrao
        var configid = this.props.match.params["configid"];
        // define o state para a edição de um config
        if (configid > 0) {
            this.populateData(configid);
        }
        else // define o state para adição de config
        {
            this.state = { title: "Criar Parâmetro", loading: false, configData: new ConfigData };
        }
        // este binding é necessário para fazer o 'this' funcionar no callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    render() {
        let conteudo = this.state.loading
            ? <p><em>Carregando...</em></p>
            : this.renderCreateForm();
        return <div>
            <h2>{this.state.title}</h2>
            <hr />
            {conteudo}
        </div>;
    }
    // trata o evento submit do formulario
    async handleSave(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        var object = {};
        data.forEach(function (value, key) {
            if (key.toLowerCase() != 'id')
                object[key] = value;
        });
        var json = JSON.stringify(object);
        // PUT solicitação para editar config
        if(this.state.configData.id) {
            json = json.replace("{", "{\"id\":" + this.state.configData.id + ",");
            const path = 'api/Config/' + this.state.configData.id;
            let resp = "";
            try {
                const options = { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: json };
                fetch(path, options).then((response) => console.log(response.json()))
                    .then(respJason => {
                            this.props.history.push("/config");
                    })
            } catch (e) { console.log(e); }
            /*
            try {
                resp = await fetch(path, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => console.log(response.json()))
                    .then((responseJson) => {
                        this.props.history.push("/config");
                    })
            } catch (e) { console.log(e); }

            if (resp.status == 401) {
                console.log("Usuário não autorizado para efetuar esta operação");
                window.confirm("Usuário não autorizado para efetuar esta operação")
                this.props.history.push("/config");
            }
            else
                console.log(resp);*/
        }
        else // POST requisição para adicionar config
        {
            try {
                fetch('api/Config', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => response.json())
                    .then((responseJson) => {
                        this.props.history.push("/config");
                    })
            } catch (e) { console.log(e); }
        }
    }
    // trata o evento do botão cancela
    handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/config");
    }
    // Retorna o formulario HTMl para o método Render
    renderCreateForm() {
         return (
            <form onSubmit={this.handleSave} >
                <div className="form-group row" >
                    <input type="hidden" name="Id" value={this.state.configData.id} />
                </div>
                < div className="form-group row" >
                     <label className=" control-label col-md-12" htmlFor="parametro">Parâmetro</label>
                    <div className="col-md-6">
                        <input className="form-control" type="text" name="parametro"
                            defaultValue={this.state.configData.parametro} required />
                    </div>
                </div >
                <div className="form-group row">
                    <label className="control-label col-md-12" htmlFor="valor" >Valor</label>
                    <div className="col-md-6">
                         <input className="form-control" type="text" name="valor"
                             defaultValue={this.state.configData.valor} required />
                    </div>
                 </div>
                 <div className="form-group row">
                     <label className="control-label col-md-12" htmlFor="tipo" >Tipo</label>
                     <div className="col-md-6">
                         <input className="form-control" type="text" name="tipo"
                             defaultValue={this.state.configData.tipo} required />
                     </div>
                 </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Salvar</button>
                    <button className="btn btn-primary" onClick={this.handleCancel}>Cancelar</button>
                </div >
            </form >
        );
    }

    async populateData(configid) {
        const response = await fetch('api/Config/' + configid);
        if (response.status == 401)
            this.props.history.push("/signin");
        const data = await response.json();
        this.setState({ title: "Editar Parâmetro", loading: false, configData: data });
    }
}  