import { getConfigValue } from '../components/Util';

export const TOKEN_KEY = "@DocDocs-Token";
export const USER = "@DocDocs-User";
export const COMPANY = "@DocDocs-Company";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
//export const isAuthenticated = () => true;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser = () => {
    if (!isAuthenticated()) return null;
    return JSON.parse(atob(inverter(localStorage.getItem(USER), 0, 100)));
}
export const getCompany = () => localStorage.getItem(COMPANY);
export const login = async (user, token) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER, inverter(btoa(JSON.stringify(user)), 0, 100));
    localStorage.setItem(COMPANY, await getConfigValue('empresa'));
};
export const logout = () => {
    if (!isAuthenticated()) return
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER);
    localStorage.removeItem(COMPANY);
};

function inverter(str, ini, end) {
    var o = '';
    if (str == null)
        return null;
    end = end > str.length ? str.length : end
    ini = ini > end ? 0 : ini    
    for (var i = end - 1; i >= ini; i--) {
        o += str[i];
    }
    const split = str.substring(ini, end);
    return str.replace(split, o);
}