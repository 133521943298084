import React, { Component } from 'react';

// get our fontawesome imports
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../custom.css'

export class Logs extends Component {
    static displayName = Logs.name;

  constructor(props) {
    super(props);
      this.state = { listaLogs: [], strFilter: '', loading: true };
      // este binding é necessário para que o 'this' funcione no callback  
      this.handleDelete = this.handleDelete.bind(this);
      this.handleEdit = this.handleEdit.bind(this);
      this.handleFilter = this.handleFilter.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

    renderDataTable(listaLogs) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Data</th>
            <th>Usuário</th>
            <th>Tipo</th>
            <th>Mensagem</th>
          </tr>
        </thead>
        <tbody>
          {listaLogs.map(log =>
            <tr key={log.id}>
                <td>{log.data_hora}</td>
                <td>{log.id_usuario}</td>
                <td>{log.tipo}</td>
                <td>{log.mensagem}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
}

render() {
        let contents;
        if (this.state.loading) {
            contents = <p><em>Carregando...</em></p>
        } else {
            let filtered = this.state.listaLogs.filter((rec) => {
                return (rec.id.toString().indexOf(this.state.strFilter) != -1 ||
                    rec.data_hora.indexOf(this.state.strFilter) != -1 ||
                    rec.id_usuario.toString().indexOf(this.state.strFilter) != -1 ||
                    rec.tipo.indexOf(this.state.strFilter) != -1 ||
                    rec.mensagem.indexOf(this.state.strFilter) != -1  
                );
            });
            contents = this.renderDataTable(filtered);
        }

    return (
        <div>
            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar com grupos de botões">
                <div class="btn-group" role="group" aria-label="Primeiro grupo">
                    <h2 id="tabelLabel" >Logs</h2>
                </div>
                <form onSubmit={this.handleFilter} class="form-inline">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-btn">
                                <input class="form-control ml-4" type="text" onKeyDown={this.handleKeyDown} onChange={this.handleChange} aria-describedby="btnGroupAddon2" placeholder="Digite o texto da busca..." name="filter" />
                                <button class="btn btn-primary" id="btnGroupAddon2" type="submit">
                                    <spin><FontAwesomeIcon icon={faSearch} /></spin> Busca
				                    </button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            {contents}
        </div>
    );
  }

    // Trata a solicitação Delete  para um logs  
    handleDelete(id, nome) {
        if (!window.confirm("Deseja deletar o logs: " + nome))
            return;
        else {
            fetch('api/Logs/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        listaLogs: this.state.listaLogs.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
            });
        }
    }

    handleEdit(id) {
        this.props.history.push("/editLogs/" + id);
    }

    handleFilter(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const strf = data.get('filter').toString();
        this.setState({ strFilter: strf});
    }

    handleChange(e) {
        this.setState({ strFilter: e.target.value });
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            this.render();
        }
    }

    async populateData() {
        const response = await fetch('api/Logs');
        if (response.status == 401)
            this.props.history.push("/signin");
        const data = await response.json();
        this.setState({ listaLogs: data, loading: false });
    }
}

