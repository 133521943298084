import { getToken } from "./auth";
import fetchIntercept from 'fetch-intercept';

export const fetchunregister = fetchIntercept.register({
    request: function (url, config) {
        const token = getToken();
        if (token) {
            if (config == undefined) {
                config = { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } }
            }
            else if (config.method == "delete") {
                config = { method: 'delete', headers: { 'Authorization': `Bearer ${token}` } }
            }
            else {
                if(config.headers == undefined)
                    config.headers = { 'Content-type': 'multipart/form-data' }
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        console.log(url);
        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        console.log(error);
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        if (response.status == 403) {
            window.confirm("Usuário não autorizado para efetuar esta operação.")
        }
        else if (response.status == 401) {
            window.confirm("Usuário não autenticado. Efetue login.")
        }
        console.log(response);
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        console.log(error);
        return Promise.reject(error);
    }
});

//export default fetchunregister;
