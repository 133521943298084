import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ExameData } from './Exame';
import { ConfigData } from './Config';
import { log, idade, formatDate, today, getConfigValue } from './Util';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { FileUpdate } from './FileUpdate';
import axios, { post } from 'axios';

export class AddExame extends Component {
    constructor(props) {
        super(props);
        //atualiza o state do componente
        this.state = { title: "", loading: true, exameData: new ExameData, desable: false, audio: "", pathAudio: "" };
        var linha1 = document.getElementById("linha1");
        var linha2 = document.getElementById("linha2");
        var linha3 = document.getElementById("linha3");
        var linha4 = document.getElementById("linha4");
        var linha5 = document.getElementById("linha5");

        //var \

        //id do exameiente
        var exameid = this.props.match.params["exameid"];
        // define o state para a edição/adição de um exame
        this.populateData(exameid);

        // este binding é necessário para fazer o 'this' funcionar no callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleTipoMsgChange = this.handleTipoMsgChange.bind(this);
        this.handleModelosMsgChange = this.handleModelosMsgChange.bind(this);
        this.handleAudioChange = this.handleAudioChange.bind(this);
        this.handleUploadAudio = this.handleUploadAudio.bind(this);
        this.handleUploadAudioFileChange = this.handleUploadAudioFileChange.bind(this);
    }

    render() {
        let conteudo = this.state.loading
            ? <p><em>Carregando...</em></p>
            : this.renderCreateForm();
        return <div>
            <h2>{this.state.title}</h2>
            <hr />
            {conteudo}
        </div>;
    }

    // Atualiza exame atual
    /*async updateExameAtual() {
        const checkExameAtual = document.getElementById("exame_atual");
        const isExameAtual = checkExameAtual.value == "on"
        const idExameNovo = String(this.state.exameData.id)
        const idExameAtual = this.state.configData.valor
        var jsondata = ""
        if (isExameAtual && idExameNovo != idExameAtual)
            jsondata = "{\"id\":" + this.state.configData.id + "," + "\"parametro\":\"" + this.state.configData.parametro + "\",\"valor\":\"" + idExameNovo + "\"}";
        else if (!isExameAtual && idExameNovo == idExameAtual)
            jsondata = "{\"id\":" + this.state.configData.id + "," + "\"parametro\":\"" + this.state.configData.parametro + "\",\"valor\":\"0\"}";
        else
            return;

        const path = 'api/Config/' + this.state.configData.id;

        try {
            fetch(path, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: jsondata,
            }).then((response) => log("EDIT", "Alterou reg Config: " + jsondata))
                .then((responseJson) => {
                    //this.props.history.push("/exame");
                })
        } catch (e) { console.log(e); }
    }*/

    AtualizaVideoMensagens(isChange) {
        const comboPosicao = document.getElementById("cboPosicao");
        let tipoMsg = comboPosicao.value
        let exame = this.state.exameData;
        let msg = this.linha1.value + '|' + this.linha2.value + '|' + this.linha3.value + '|' + this.linha4.value + '|' + this.linha5.value
        if (tipoMsg === "A") {
            if (isChange) {
                exame.msg_fechamento = msg
                msg = exame.msg_abertura
            } else
                exame.msg_abertura = msg
        }
        else {
            if (isChange) {
                exame.msg_abertura = msg
                msg = exame.msg_fechamento
            } else
                exame.msg_fechamento = msg
        }
        if (isChange) {
            this.AtualizaCamposMsg(msg)
        }

        this.setState({ exameData: exame })
    }

    // Atualiza view dos campos de mensagem
    AtualizaCamposMsg(text) {
        let linhas = text.split("|", 5)
        this.linha1.value = linhas[0] == undefined ? "" : linhas[0]
        this.linha2.value = linhas[1] == undefined ? "" : linhas[1]
        this.linha3.value = linhas[2] == undefined ? "" : linhas[2]
        this.linha4.value = linhas[3] == undefined ? "" : linhas[3]
        this.linha5.value = linhas[4] == undefined ? "" : linhas[4]
    }

    // trata o evento de alteração de valor do tipo de mensagem
    async handleUploadAudio(e) {
        e.preventDefault();
        const selectFile = document.getElementById("uploadfile");
        const comboAudio = document.getElementById("cboAudio");
        var textAudio = comboAudio.children[comboAudio.selectedIndex].textContent;
        if (textAudio != "Nenhum")
            await selectFile.click()
        else
            alert("Selecione o audio da lista a ser substituido !")
    }

    async handleUploadAudioFileChange(e) {
        e.preventDefault();
        const comboAudio = document.getElementById("cboAudio");
        var textAudio = comboAudio.children[comboAudio.selectedIndex].textContent;
        const selectFile = document.getElementById("uploadfile");
        const file = selectFile.files[0]
        if (file.name == undefined || textAudio == "Nenhum")
            return;
        if (!window.confirm("Deseja substituir o audio \"" + textAudio +"\" pelo audio: \"" + file.name+"\".\nEsta atualização passará a ser utilizada para todos os novos exames a serem realizados.\nConfirma a atualização ?"))
            return;
        let formData = new FormData();
        //const filename = textAudio.split(' ')[1]+"Audio.mp3"        
        //const urlpath = await getConfigValue("path_file")
        formData.append('file', file, "UPDT_"+this.state.audio.replace(this.state.pathAudio, ""));
        //formData.append('url', urlpath);
        try {

            //let response = await fetch('api/audio');
            //let textaud = await response.text();
            //alert(textaud)
                        
            const url = 'api/audio';
            /*const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }*/
            let resp = await post(url, formData)

            alert("Arquivo \"" + textAudio+"\" foi enviado para o sevidor.\nPara efetivar a atualização, o equipamento de coleta deve ser reinicializado !")

            console.log("Novo audio atualizado." + resp.status);

            //let textaudpost = await resp.json();

        } catch (e) {
            console.log(e);
        }

        //const nome = selectFile.files[0].name
        //this.AtualizaVideoMensagens(true)
    }

    // trata o evento de alteração de valor do tipo de mensagem
    handleTipoMsgChange(e) {
        e.preventDefault();
        this.AtualizaVideoMensagens(true)
    }

    // trata o evento de alteração de valor do audio
    handleAudioChange(e) {
        e.preventDefault();
        let exame = this.state.exameData
        const comboAudio = document.getElementById("cboAudio");

        var audio
        if (comboAudio.value == "11")
            audio = "01Audio.mp3"
        else if (comboAudio.value == "2")
            audio = "02Audio.mp3"
        else if (comboAudio.value == "3")
            audio = "03Audio.mp3"
        else if (comboAudio.value == "4")
            audio = "04Audio.mp3"
        else if (comboAudio.value == "5")
            audio = "05Audio.mp3"
        else if (comboAudio.value == "6")
            audio = "06Audio.mp3"
        else if (comboAudio.value == "7")
            audio = "07Audio.mp3"
        else if (comboAudio.value == "8")
            audio = "08Audio.mp3"
        else if (comboAudio.value == "9")
            audio = "09Audio.mp3"
        else if (comboAudio.value == "10")
            audio = "10Audio.mp3"
        //const srcAudio = document.getElementById("sourceAudio");

        audio = this.state.pathAudio + audio
        exame.audio = parseInt(comboAudio.value)
        //srcAudio.src = audio
        this.setState({ exameData: exame, audio: audio })
    }

    // trata o evento de alteração de valor do modelo de mensagem
    handleModelosMsgChange(e) {
        e.preventDefault();
        const comboPosicao = document.getElementById("cboPosicao");
        const comboModelos = document.getElementById("cboModelos");
        let tipoMsg = comboPosicao.value
        let msg = ""
        let value = comboModelos.value
        if (tipoMsg == "A") {
            if (value == "0")
                msg = "||||"
            else if (value == "1")
                msg = "Oi pessoal !|Estou aqui na barriga da mamãe !|Prestem atenção...|Eu vou me apresentar...|Olha eu aí..."
            else if (value == "2")
                msg = "********************************|E aí pessoal !|Querem me conhecer, vem comigo...|Olha eu aí...|********************************"
            else if (value == "3")
                msg = "Oi pessoal !|Estou aqui na barriga da mamãe !|Eu já tenho algumas semanas...|Estou crescendo e me desenvolvendo bem !|Olha eu aí..."
            else if (value == "4") {
                const comboPacientes = document.getElementById("cboPacientes");
                let str = comboPacientes.options[comboPacientes.selectedIndex].text
                const paciente = str.substr(0, str.indexOf(" -"))
                const comboEP = document.getElementById("cboEP");
                const ep = comboEP.options[comboEP.selectedIndex].text
                const data = today();
                msg = "-------------------------------| Exame: " + ep + " | Paciente: " + paciente + " | Data: " + data + " |-------------------------------"
            }
        } else {
            if (value == "0")
                msg = "||||"
            else if (value == "1")
                msg = "Por enquanto é isso pessoal !|Espero que tenham gostado...|Logo estaremos juntos...|Me aguardem !|Estou chegando..."
            else if (value == "2")
                msg = "********************************|É isso pessoal !|Em breve estaremos juntos...|Me aguardem !|********************************"
            else if (value == "3")
                msg = "Por enquanto é isso pessoal !|Aguardem mais algumas semanas...|Então estaremos juntos...|Me aguardem...|Até breve..."
            else if (value == "4") {
                const comboPacientes = document.getElementById("cboPacientes");
                let str = comboPacientes.options[comboPacientes.selectedIndex].text
                const paciente = str.substr(0, str.indexOf(" -"))
                const comboEP = document.getElementById("cboEP");
                const ep = comboEP.options[comboEP.selectedIndex].text
                const data = today();
                msg = "-------------------------------| Exame: " + ep + " | Paciente: " + paciente + " | Data: " + data + " |-------------------------------"
            }
        }
        this.AtualizaCamposMsg(msg)
    }

    // trata o evento submit do formulario
    handleSave(e) {
        e.preventDefault();        
        var object = {};
        var isExameAtual = false;
        this.AtualizaVideoMensagens(false)
        if (this.state.disabled) {
            object = this.state.exameData
        }
        else {
            const data = new FormData(e.target);
            data.forEach(function (value, key) {
                if (key.toLowerCase() == 'exame_atual') {
                    isExameAtual = value == 'on' ? true : false
                }
                else if (key.toLowerCase() == 'id_paciente') {
                    if ((object[key] = parseInt(value, 10)) === 0) {
                        window.alert("Paciente não pode ser nulo, escolha o paciente !");
                        return;
                    }
                }
                else if (key.toLowerCase() == 'id_exame_padrao') {
                    if ((object[key] = parseInt(value, 10)) === 0) {
                        window.alert("Exame padrão não pode ser nulo, escolha o exame padrão !");
                        return;
                    }
                }
                else if (key.toLowerCase() != 'id')
                    object[key] = value;
            });
        }
        var json = JSON.stringify(object);
        if (!this.state.disabled) {
            json = json.replace("}", ",\"msg_abertura\":\"" + this.state.exameData.msg_abertura + "\",\"msg_fechamento\":\"" + this.state.exameData.msg_fechamento +
                                    "\",\"audio\":" + (this.state.exameData.audio) + "}");
        }

        // PUT solicitação para editar exame
        if(this.state.exameData.id) {
            json = json.replace("{", "{\"id\":" + this.state.exameData.id + ",");
            const path = 'api/Exames/' + this.state.exameData.id;
            try {
                //this.updateExameAtual()
                fetch(path, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json,
                }).then((response) => log("EDIT", "Alterou reg Exame: " + json))
                    .then((responseJson) => {
                        this.props.history.push("/exame/1");
                    })
            } catch (e) { console.log(e); }

        }
        else // POST requisição para adicionar exame
        {
            try {                
                fetch('api/Exames', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: json, 
                }).then((response) => log("INSERT", "Novo reg Exame: " + json))
                    .then((responseJson) => {
                        this.props.history.push("/exame/1");
                    })
            } catch (e) { console.log(e); }
        }
    }
    // trata o evento do botão cancela
    handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/exame/1");
    }

    // Carrega o combobox de Pacientes
    async loadComboPacientes() {
        /**
         * Carregando a combobox Pacientes
         */
        const data = await fetch('api/Pacientes');
        const listPacientes = await data.json();
        const comboPacientes = document.getElementById("cboPacientes");
        var opt = document.createElement("option");
        opt.value = "0";
        opt.text = "";
        comboPacientes.add(opt, comboPacientes.options[0]);
        for (var i = 0; i < listPacientes.length; i++) {
            opt = document.createElement("option");
            opt.value = listPacientes[i].id;
            opt.text = listPacientes[i].nome + " - " + listPacientes[i].id;
            comboPacientes.add(opt, comboPacientes.options[i+1]);
        };
        if (this.state.exameData.id_paciente)
            comboPacientes.value = this.state.exameData.id_paciente;
    }

    // Carrega o combobox de exame padrao
    async loadComboExamePadrao() {
        /**
         * Carregando a combobox de exame padrao
         */
        const data = await fetch('api/ExamePadrao');
        const listEP = await data.json();
        const comboEP = document.getElementById("cboEP");
        var opt = document.createElement("option");
        opt.value = "0";
        opt.text = "";
        comboEP.add(opt, comboEP.options[0]);
        for (var i = 0; i < listEP.length; i++) {
            opt = document.createElement("option");
            opt.value = listEP[i].id;
            opt.text = listEP[i].nome
            comboEP.add(opt, comboEP.options[i + 1]);
        };
        if (this.state.exameData.id_exame_padrao)
            comboEP.value = this.state.exameData.id_exame_padrao;        
    }


    renderCreateForm() {        
        return (
            <MDBContainer>
                <MDBRow>
                    <MDBCol size="5">
                        <MDBRow>
                            <form onSubmit={this.handleSave} >
                                <div className="form-group row" >
                                    <input type="hidden" name="Id" value={this.state.exameData.id} />
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-md-12" htmlFor="id_paciente">Paciente</label>
                                    <div className="col-md-12">
                                        <select className="form-control" id="cboPacientes" name="id_paciente" disabled = {(this.state.disabled) ? "disabled":""}
                                            defaultValue={"Paciente"} required>
                                        </select>
                                    </div>
                                    
                                </div >
                                < div className="form-group row" >
                                    <label className=" control-label col-md-12" htmlFor="id_exame_padrao">Exame</label>
                                    <div className="col-md-12">
                                        <select className="form-control" id="cboEP" name="id_exame_padrao" disabled = {(this.state.disabled) ? "disabled":""}
                                            defaultValue={"EP"} required>
                                        </select>
                                    </div>

                                </div >
                                <div className="form-group row">
                                    <label className="control-label col-md-12" htmlFor="Descricao" >Descrição do Exame</label>
                                    <div className="col-md-12">
                                        <input className="form-control" type="text" name="descricao" disabled = {(this.state.disabled) ? "disabled":""}
                                            defaultValue={this.state.exameData.descricao} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-md-12" htmlFor="Data" >Data</label>
                                    <div className="col-md-12">
                                        <input className="form-control" type="date" name="data" disabled = {(this.state.disabled) ? "disabled":""}
                                            defaultValue={this.state.exameData.data} required />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-md-12" htmlFor="Convenio" >Convênio</label>
                                    <div className="col-md-12">
                                        <input className="form-control" type="text" name="convenio" disabled = {(this.state.disabled) ? "disabled":""}
                                            defaultValue={this.state.exameData.convenio} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-md-12" htmlFor="Solicitante" >Solicitante</label>
                                    <div className="col-md-12">
                                        <input className="form-control" type="text" name="solicitante" disabled = {(this.state.disabled) ? "disabled":""}
                                            defaultValue={this.state.exameData.solicitante} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Salvar</button>
                                    <button className="btn btn-primary" onClick={this.handleCancel}>Cancelar</button>
                                </div >
                            </form >
                        </MDBRow>
                    </MDBCol>
                    <MDBCol size="7">
                        {/*
                        <MDBRow>
                                <FileUpdate/>
                        </MDBRow>
                        {/**/}
                        <MDBRow>
                            <div className="form-group row">
                                <label className=" control-label col-md-3" htmlFor="posicao">Tipo Mensagem</label>
                                <label className=" control-label col-md-3" htmlFor="modelos">Modelo</label>
                                <label className=" control-label col-md-1" htmlFor="audio">Audio</label>
                                <div className="col-md-3">
                                    <select className="form-control" id="cboAudio" name="audio" onChange={this.handleAudioChange}
                                        defaultValue="11" required>
                                        <option value="11">Audio 1</option>
                                        <option value="2">Audio 2</option>
                                        <option value="3">Audio 3</option>
                                        <option value="4">Audio 4</option>
                                        <option value="5">Audio 5</option>
                                        <option value="6">Audio 6</option>
                                        <option value="7">Audio 7</option>
                                        <option value="8">Audio 8</option>
                                        <option value="9">Audio 9</option>
                                        <option value="10">Audio 10</option>
                                        <option value="12">Nenhum</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <input id='uploadfile' type='file' style={{ width: '0px' }} onChange={this.handleUploadAudioFileChange}/>                                    
                                    <button className="btn btn-primary" id='browser' onClick={this.handleUploadAudio}>
                                            Upload
                                    </button>
                                </div>
                                {/*<label className=" control-label col-md-4" htmlFor="exame_atual"
                                    style={{ visibility: this.state.exameData.id > 0 ? 'visible' : 'hidden' }}>Habilitar Gravação</label>
                                */}
                            
                                <div className="col-md-3">
                                    <select className="form-control" id="cboPosicao" name="posicao" onChange={this.handleTipoMsgChange}
                                        defaultValue="A" required>
                                        <option value="A">Abertura</option>
                                        <option value="F">Fechamento</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select className="form-control" id="cboModelos" name="modelos" onChange={this.handleModelosMsgChange}
                                        defaultValue="1" required>
                                        <option value="0">Nenhuma</option>
                                        <option value="1">Padrão</option>
                                        <option value="2">Alegre</option>
                                        <option value="3">Semanas</option>
                                        <option value="4">Informativo</option>
                                    </select>
                                </div>
                                    
                                    <div className="col-md-6">
                                        <audio source src={this.state.audio} id="sourceAudio" type="audio/mpeg" controls>
                                        </audio>
                                    </div>
                                {/*<div className="col-md-4">
                                    <input className="form-control" type="checkbox" id="exame_atual" name="exame_atual"
                                        style={{ visibility: this.state.exameData.id > 0 ? 'visible' : 'hidden' }}
                                        defaultChecked={String(this.state.exameData.id) == this.state.configData.valor} />
                                </div>*/}
                            </div>
                        </MDBRow>
                        <MDBRow>
                            <div className="form-group row">
                                <label className="control-label col-md-12" htmlFor="msglinha1" >Mensagem Linha 1</label>
                                
                                <div className="col-md-12">
                                    <input className="form-control" type="text" maxlength="40" name="linha1" id="linha1"
                                defaultValue="" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12" htmlFor="msglinha2" >Mensagem Linha 2</label>
                                <div className="col-md-12">
                                    <input className="form-control" type="text" maxlength="40" name="linha2" id="linha2"
                                defaultValue="" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12" htmlFor="msglinha3" >Mensagem Linha 3</label>
                                <div className="col-md-12">
                                    <input className="form-control" type="text" maxlength="40" name="linha3" id="linha3"
                                defaultValue="" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12" htmlFor="msglinha4" >Mensagem Linha 4</label>
                                <div className="col-md-12">
                                    <input className="form-control" type="text" maxlength="40" name="linha4" id="linha4"
                                defaultValue="" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12" htmlFor="msglinha5" >Mensagem Linha 5</label>
                                <div className="col-md-12">
                                    <input className="form-control" type="text" maxlength="40" name="linha5" id="linha5"
                                defaultValue="" />
                                </div>
                            </div>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }

    async populateData(examid) {
        //let pathAudio = await getConfigValue('path_file')
        //pathAudio = pathAudio + "audio/"
        //const location = useLocation()
        const origin = window.location.origin
        const ini = origin.indexOf("://") + 3;
        const end = origin.indexOf(".") ;
        const client = (origin.indexOf("localhost")) != -1 ?
            "localhost" :
            origin.substring(ini, end);
        let pathAudio;
        if (client != "localhost")
            pathAudio = origin.substring(0, ini) + origin.substring(end + 1);
        pathAudio = pathAudio + "/assets/" + client + "/audio/";
        let dataexame = new ExameData;
        let tit = "Criar Exame"

        if (examid > 0) { // Editar exame
            const response = await fetch('api/Exames/' + examid);
            dataexame = await response.json();
            tit = "Editar Exame"
        }

        const isDisabled = (dataexame.diagnostico || dataexame.laudado || dataexame.envio >= 4)

        var audio
        var audioValue = dataexame.audio
        if (audioValue == "11")
            audio = "01Audio.mp3"
        else if (audioValue == "2")
            audio = "02Audio.mp3"
        else if (audioValue == "3")
            audio = "03Audio.mp3"
        else if (audioValue == "4")
            audio = "04Audio.mp3"
        else if (audioValue == "5")
            audio = "05Audio.mp3"
        else if (audioValue == "6")
            audio = "06Audio.mp3"
        else if (audioValue == "7")
            audio = "07Audio.mp3"
        else if (audioValue == "8")
            audio = "08Audio.mp3"
        else if (audioValue == "9")
            audio = "09Audio.mp3"
        else if (audioValue == "10")
            audio = "10Audio.mp3"

        audio = pathAudio+audio

        this.setState({ title: tit, loading: false, exameData: dataexame, disabled: isDisabled, audio: audio, pathAudio: pathAudio });
        this.linha1 = document.getElementById("linha1");
        this.linha2 = document.getElementById("linha2");
        this.linha3 = document.getElementById("linha3");
        this.linha4 = document.getElementById("linha4");
        this.linha5 = document.getElementById("linha5");
        let linhas = dataexame.msg_abertura.split("|", 5)
        this.linha1.value = linhas[0] == undefined ? "" : linhas[0]
        this.linha2.value = linhas[1] == undefined ? "" : linhas[1]
        this.linha3.value = linhas[2] == undefined ? "" : linhas[2]
        this.linha4.value = linhas[3] == undefined ? "" : linhas[3]
        this.linha5.value = linhas[4] == undefined ? "" : linhas[4]
        await this.loadComboPacientes();
        await this.loadComboExamePadrao();
        const comboAudio = document.getElementById("cboAudio");
        comboAudio.value = this.state.exameData.audio
    }
}  